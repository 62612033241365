import { Params } from 'assets/const';
import { useLocation, useSearchParams } from 'react-router-dom'


export const useReturnUrl = () => {
  const [params] = useSearchParams()
  const returnUrl = params.get(Params.ReturnUrl) || ''
  const returnUrlParam = `${Params.ReturnUrl}=${returnUrl}`

  return {
    returnUrl,
    returnUrlParam,
  }
}

export const useReturnUrlfromSettings = () => {
  const {search} = useLocation()
  const returnUrl = search.replace('?returnUrl=', '');
  return {
    returnUrl,
  }
}