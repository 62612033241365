import { FC, Dispatch, SetStateAction } from 'react';
import s from './CustomTabs.module.scss';


interface Tab {
  label: string;
  value: number;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: number
  setActiveTab: Dispatch<SetStateAction<number>>;
}

const CustomTabs: FC<TabsProps> = ({ tabs, setActiveTab, activeTab }) => {

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className={s.tabsContainer}>
      <div className={s.tabsHeader}>
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${s.tab} ${activeTab === index ? s.active : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomTabs;