import { useEffect, useRef, useState } from 'react';

import Paper from 'components/Paper'
import { BankAccount } from '../../../types/external-account'
import Typography from 'components/Typography'
import { MdMoreVert } from 'react-icons/md';

import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteIcon.svg'

import s from '../ExternalAccounts.module.scss'


interface Props {
  account: BankAccount
  onEdit: () => void
  onDelete: () => void
}

const MobileBankAccount = ({ account, onEdit, onDelete }: Props) => {
  const {id, accountNumber, accountHolderName, routingNumber, country, currency} = account
  const [isOpen, setIsOpen] = useState(false)

  const dropdownRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as HTMLElement)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Paper className={s.mobilePaperCard}>
        <div>
          <div className={s.mobileItem}>
            <Typography variant='h6'>ID: </Typography>
            <Typography variant='h4' className={s.mobileItemValue}>{id}</Typography>
          </div>
          <div className={s.mobileItem}>
            <Typography variant='h6'>Account №: </Typography>
            <Typography variant='h4'>{accountNumber}</Typography>
          </div>
          <div className={s.mobileItem}>
            <Typography variant='h6'>Account holder name:</Typography>
            <Typography variant='h4'>{accountHolderName}</Typography>
          </div>
          <div className={s.mobileItem}>
            <Typography variant='h6'>Routing number:</Typography>
            <Typography variant='h4'>{routingNumber}</Typography>
          </div>
          <div className={s.mobileItem}>
            <Typography variant='h6'>Country:</Typography>
            <Typography variant='h4'>{country}</Typography>
          </div>
          <div className={s.mobileItem}>
            <Typography variant='h6'>Currency:</Typography>
            <Typography variant='h4'>{currency}</Typography>
          </div>
        </div>

        <div className={s.mobileActionWrapper} >
          <MdMoreVert fontSize={24} onClick={() => setIsOpen((prev) => !prev)}/>
          {isOpen &&
            <div
              ref={dropdownRef}
              className={s.mobileActionsMenuShow}
            >
              <EditIcon className={s.icon} onClick={onEdit}/>
              <DeleteIcon className={s.icon} onClick={onDelete}/>
            </div>
          }
        </div>
      </Paper>
    </>
  )
}

export default MobileBankAccount
