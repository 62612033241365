import { useCallback, useState } from 'react'

import Button from 'components/Button'
import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Popup from 'components/Popup'
import Typography from 'components/Typography'
import BankAccountForm from './AddModal'
import Preloader from 'components/Preloader'
import DetailsModal from './DetailsModal'

import { PageHeading } from 'assets/const'
import { useSseStore } from 'store/useSseStore'
import { useGetMangoBankAccountsList } from 'hooks/data/useMangoApi'
import { MangoBankAccount } from 'types/mango'
import { MangoExternalBankAccountsService } from 'services/mango-external-bank-accounts.service'

import { ReactComponent as ActiveIcon } from 'assets/svg/Active.svg'
import { ReactComponent as TrashIcon } from 'assets/svg/Trash.svg'
import { ReactComponent as InfoIcon } from 'assets/svg/Info.svg'

import s from './ExternalAccounts.module.scss'


const MangoBankAccounts = () => {
  const [currentAccount, setCurrentAccount] = useState<MangoBankAccount | null>(null)
  const { mangoBankAccounts, setLoading, loading, modal, setModal } = useSseStore()

  useGetMangoBankAccountsList()

  const addNewAccount = useCallback(() => {
    setModal('ADD')
  }, [])

  const onDelete = useCallback((account: MangoBankAccount) => {
    if (account?.isActive) {
      setCurrentAccount(account)
      setModal('REMOVE')
    }
  }, [])

  const openDetails = useCallback((account: MangoBankAccount) => {
    setCurrentAccount(account)
    setModal('DETAILS')
  }, [])

  const deleteAcc = () => {
    const id = currentAccount?.bankAccountId
    if (!id) return
    MangoExternalBankAccountsService.delete(id).then(() => {
      setLoading(true)
    })
  }

  const onClose = () => {
    setCurrentAccount(null)
    setModal(null)
  }

  return (
    <>
      {loading && <Preloader />}
      <Paper textAlign='center'>
        <PaperHeader text={PageHeading.ExternalAccounts.Bank} />
        <div className={s.tableWrapper}>
          <div className={s.tableContainer}>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Account number</th>
                  <th>Account holder name</th>
                  <th>Tag</th>
                  <th>Active</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody  className={s.scrollContainer}>
                {!mangoBankAccounts?.length ? (
                  <tr className={s.noAccounts}>
                    <td colSpan={7}> No accounts</td>
                  </tr>
                ) : (
                  mangoBankAccounts.map((account) => {
                    return (
                      <tr key={account.bankAccountId} className={s.grid}>
                        <td className={s.textEllipsis}>{account.bankAccountId}</td>
                        <td className={s.textEllipsis}>{account.type === 'IBAN' ? account.iban : account.accountNumber}</td>
                        <td>{account.ownerName}</td>
                        <td>{account.tag}</td>
                        <td>
                          <div className={s.actionsWrapper}>
                            {account.isActive && <ActiveIcon/>}
                          </div>
                        </td>
                        <td>
                          <div className={s.actionsWrapper}>
                            <TrashIcon
                              className={account.isActive ? s.icon : undefined}
                              onClick={() => onDelete(account)}
                            />
                            <InfoIcon
                              className={s.icon}
                              onClick={() => openDetails(account)}
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
      <div className={s.buttonsContainer}>
        <Button onClick={addNewAccount} type='submit' size='large'>
          Add Bank Account
        </Button>
      </div>

      {modal === 'ADD' && <BankAccountForm onClose={onClose} />}
      {modal === 'DETAILS' && currentAccount && <DetailsModal  onClose={onClose} id={currentAccount.bankAccountId}/>}
      <Popup isOpen={modal==='REMOVE'} onClose={onClose}>
        <div className={s.deletePopup}>
          <Typography variant='h4'>
            Are you sure you want to delete this account?
          </Typography>
          <Typography variant='h4' className={s.idText}>{currentAccount?.bankAccountId}</Typography>
          <div className={s.buttonsRemoveBlock}>
            <Button
              onClick={deleteAcc}
              type='button'
              variant='outlined'
              size='medium'
            >
              Deactivate
            </Button>
            <Button onClick={onClose} size='medium'>
              Cancel
            </Button>
          </div>
        </div>
      </Popup>
    </>
  )
}

export default MangoBankAccounts
