import useSWR from 'swr/immutable';
import { FinanceService } from 'services/finance.service';
import { PaymentWalletsReq, Wallet } from 'types/payment-settings';

export const useWalletsForInvoice = (props: PaymentWalletsReq | null) => {
  const fetchKey = props && props.currency && props.amount !== undefined
    ? `wallets/${props.currency}/${props.amount}`
    : null;

  const { data, error, isValidating } = useSWR<Wallet[] | null, Error>(
    fetchKey,
    () => {
      return props ? FinanceService.getWalletsForInvoice({ currency: props.currency, amount: props.amount }) : null;
    },
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      revalidateIfStale: true,
    }
  );

  return {
    data,
    error,
    isWalletsLoading: isValidating,
  };
};