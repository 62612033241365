import { Controller, Control } from 'react-hook-form'
import { useDeferredValue, useMemo, useRef, useState, useEffect } from 'react'
import TextField from 'components/TextField'
import s from './CustomInputSelect.module.scss'
import { useTranslation } from 'react-i18next'

export type SelectOption = {
  label: string
  value: string
}

interface Props {
  size?: 'medium' | 'small'
  label: string
  required?: boolean
  className?: string
  options: SelectOption[]
  control: Control<any>
  name: string
  maxDropdownHeight?: number
  disabled?: boolean
  disableSearch?: boolean
}

const CustomFormInputSelect = ({
  size = 'medium',
  className,
  control,
  name,
  options,
  required,
  maxDropdownHeight = 200,
  label,
  disabled = false,
  disableSearch = false,
}: Props) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const [error, setError] = useState('')
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const MARGIN_DROPDOWN = 5
  const LIST_ITEM_HEIGHT = 40
  const actualLabel = `${label}${required ? '*' : ''}`
  const deferredValue = useDeferredValue(inputValue)

  const filteredOptions = useMemo(() => {
    return options.filter((item) =>
      item.label.toLowerCase().includes(deferredValue.toLowerCase())
    )
  }, [deferredValue, options])

  const positionDropdown = () => {
    if (!dropdownRef.current || !containerRef.current) return

    const currentOptionsLength = filteredOptions.length || 1
    const containerRect = containerRef.current.getBoundingClientRect()
    const containerBottom = containerRect.bottom
    const containerHeight = containerRect.height
    const dropdownHeight =
      currentOptionsLength * LIST_ITEM_HEIGHT < maxDropdownHeight
        ? currentOptionsLength * LIST_ITEM_HEIGHT
        : maxDropdownHeight

    if (
      window.innerHeight - containerBottom < dropdownHeight &&
      containerRect.top - dropdownHeight > MARGIN_DROPDOWN
    ) {
      dropdownRef.current.style.bottom =
        containerHeight + MARGIN_DROPDOWN + 'px'
      dropdownRef.current.style.top = ''
    } else {
      dropdownRef.current.style.bottom = ''
      dropdownRef.current.style.top = containerHeight + MARGIN_DROPDOWN + 'px'
    }
  }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const openDropdown = () => {
    setIsOpen(true)
  }

  const handleCaretClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    toggleDropdown()
  }

  const handleErrors = (value: string) => {
    if (required && !value) {
      setError(t('ValidationMessages.FieldRequired'))
    } else {
      setError('')
    }
  }

  useEffect(() => {
    positionDropdown()
  }, [isOpen])

  useEffect(() => {
    setHighlightedIndex(0)
    // dropdownRef.current?.children[0].scrollIntoView();
  }, [filteredOptions, isOpen])

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field, fieldState }) => {
        const currentOption = options.find(
          (option) => option.value === field.value
        )
        // const currentOption = options.find(option => option.value === field.value && option.value !== 'NotSpecified');
        const displayValue = currentOption ? currentOption.label : ''
        // if (field.value = 'NotSpecified') {fieldState.onChange('')}
        return (
          <div
            onBlur={(evt) => {
              if (
                containerRef.current &&
                !containerRef.current.contains(evt.relatedTarget)
              ) {
                closeDropdown()
                handleErrors(field.value)
              }
            }}
            ref={containerRef}
            onClick={openDropdown}
            tabIndex={0}
            className={`${s.customSelect} ${field.value ? s.dirty : ''} ${
              isOpen ? s.active : ''
            } ${s[size]} ${className ?? ''}`}
            aria-haspopup='listbox'
          >
            <TextField
              error={fieldState.error?.message || error}
              wrapperClassName={s.wrapperTextField}
              className={`${s.textField} ${disableSearch ? s.readonly : ''}`}
              label={actualLabel}
              disabled={disabled}
              value={inputValue || displayValue}
              readOnly={disableSearch}
              onChange={(e) => {
                const value = e.target.value
                setInputValue(value)
                if (!isOpen) {
                  openDropdown()
                }
                if (value === '') {
                  field.onChange('')
                }
              }}
            />
            <div
              onClick={handleCaretClick}
              className={`${s.caret} ${isOpen ? s.open : ''}`}
              role='button'
            ></div>
            <ul
              style={{ maxHeight: maxDropdownHeight + 'px' }}
              ref={dropdownRef}
              className={`${s.options} ${isOpen ? s.show : ''}`}
              role='listbox'
              tabIndex={-1}
            >
              {!filteredOptions.length && (
                <li className={s.option}>
                  <span className={s.noFound}>{`${label} ${t(
                    'NotFound'
                  )}`}</span>
                </li>
              )}
              {filteredOptions.map((option, index) => (
                <li
                  className={`${s.option} ${
                    option.value === field.value ? s.selected : ''
                  }
                  ${index === highlightedIndex ? s.highlighted : ''}`}
                  key={option.value}
                  onMouseEnter={() => setHighlightedIndex(index)}
                  onClick={(e) => {
                    e.stopPropagation()
                    field.onChange(option.value) // Устанавливаем строковое значение
                    setInputValue(option.label) // Отображаем выбранное значение
                    closeDropdown()
                  }}
                  aria-selected={option.value === field.value}
                  role='option'
                >
                  <span>{option.label}</span>
                </li>
              ))}
            </ul>
          </div>
        )
      }}
    />
  )
}

export default CustomFormInputSelect
