export const formatCamelCaseToText = (str: string) => {
  return str
    .split('')
    .map((char, index) => {
      if (char === char.toUpperCase() && index !== 0) {
        return ' ' + char.toLowerCase();
      }
      return char;
    })
    .join('')
    .replace(/^./, (match) => match.toUpperCase());
}