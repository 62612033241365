import { Link, To } from 'react-router-dom'

import Typography from 'components/Typography'

import { ReactComponent as BackArrow } from 'assets/svg/BackArrow.svg'

import s from './BackButton.module.scss'

interface Props {
  text: string
  path?: To
  forward?: boolean
  onClick?: () => void
}

const BackButton = ({ text, path, forward, onClick }: Props) => {
  const Component = path ? Link : 'button'
  return (
    <Component to={path ?? ''} className={s.backLink} onClick={onClick}>
      <BackArrow className={forward ? s.rotateArrow : ''} />
      <Typography className={s.text}>{text}</Typography>
    </Component>
  )
}

export default BackButton
