import copy from 'copy-to-clipboard'
import { useState } from 'react'

interface ClipboardOptions {
  debug?: boolean
  message?: string
  format?: string
  onCopy?: (clipboardData: object) => void
}

export const useCopyToClipboard = () => {
  const [value, setValue] = useState<string>('')
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>()

  const copyToClipboard = (text: string, options?: ClipboardOptions) => {
    const result = copy(text, options)
    if (result) setValue(text)
    setIsSuccess(result)
  }

  return {
    copyToClipboard,
    copied: value,
    isSuccess,
  }
}
