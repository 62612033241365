import { ReactComponent as ErrorIcon } from 'assets/svg/Error.svg'

import Preloader from 'components/Preloader'
import Typography from 'components/Typography'
import BackSectionLayout from 'layouts/BackSectionLayout'

import { AppRoute, PageHeading } from 'assets/const'
import { useInvoice } from 'hooks/data/useInvoice'
import { useReturnUrl } from 'hooks/useReturnUrl'

const NotFound = () => {
  const { data, isLoading, isValidating } = useInvoice()
  const { returnUrl } = useReturnUrl()

  if (isLoading || isValidating) return <Preloader />

  return (
    <BackSectionLayout
      isBackHide={!data?.redirectUrl && !returnUrl}
      path={data?.redirectUrl || returnUrl || AppRoute.Root}
      text='To the application'
    >
      <div style={{ textAlign: 'center' }}>
        <Typography as='h1' variant='h3' style={{ marginBottom: '20px' }}>
          {PageHeading.Error}
        </Typography>
        <ErrorIcon />
      </div>
    </BackSectionLayout>
  )
}

export default NotFound
