import { FC } from 'react'

import { ReactComponent as DeleteIcon } from 'assets/svg/Delete.svg'
import FileImg from 'assets/img/File.png'

import s from '../FileDropZone.module.scss'


interface Props {
  file: File
  action: (file: File) => void
}

const UploadedFile: FC<Props> = ({ file, action }) => (
  <div className={s.fileContainer}>
    <img src={FileImg}/>
    <DeleteIcon className={s.deleteIcon} onClick={() => action(file)}/>
    <span className={s.fileName}>{file.name}</span>
  </div>
)

export default UploadedFile
