import Button from 'components/Button'
import { createPortal } from 'react-dom'
import s from './Modal.module.scss'
import Typography from 'components/Typography'

interface Props {
  message: string | JSX.Element
  isOpen: boolean
  onClose: () => void
  title?: string
  icon?: JSX.Element
  buttonText?: string
}

const Modal = ({ message, onClose, isOpen, title, icon, buttonText='Close' }: Props) => {
  return (
    <>
      {createPortal(
        <div
          className={`${s.container} ${isOpen ? s.open : s.close}`}
          onClick={onClose}
        >
          {isOpen && (
            <div
              role='dialog'
              className={s.modal}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {title && <Typography variant='h4' className={s.title}>{title}</Typography>}
              {icon && icon}
              <div className={s.content}>
                {message}
              </div>
              <Button fullWidth onClick={onClose} className={s.button}>
                {buttonText}
              </Button>
            </div>
          )}
        </div>,
        document.body
      )}
    </>
  )
}

export default Modal
