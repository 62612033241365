import { Dispatch, FC, SetStateAction, useState } from 'react'
import { GoTriangleDown } from 'react-icons/go';

import Typography from 'components/Typography';
import { WalletOption } from '../index'
import { formatNumber } from 'utils/intl';
import { Currency } from 'types/payment-settings';

import s from '../CWPayment.module.scss'


interface Props {
  selectedValue: WalletOption | null
  setSelectedValue: Dispatch<SetStateAction<WalletOption | null>>
  options: WalletOption[] | null
  currency: Currency | undefined
}

const WalletsSelect: FC<Props> = ({selectedValue, setSelectedValue, options, currency}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (value: WalletOption) => {
    setSelectedValue(value);
    setIsOpen(false);
  };

  return (
    <div className={s.selectContainer} onClick={() => setIsOpen(!isOpen)}>
      <>
        {options ?
          <div className={s.selectLabel}>
            <div className={s.currencyAvatar}>{currency?.symbol}</div>
            <div className={s.labelText}>
              <Typography variant='h6'>{selectedValue?.name || options[0].name}</Typography>
              <Typography variant='h5'>{formatNumber( Number(selectedValue?.balance) || Number(options[0]?.balance), currency?.code || '')} {currency?.symbol}</Typography>
            </div>
          </div>
        : 'List of available wallets is empty'
        }
      </>
      <GoTriangleDown className={isOpen ? `${s.selectIcon}` : `${s.selectIcon} ${s.selectIconOpen}`}/>
      {isOpen && (
        <ul className={s.options}>
          {options?.map((option: WalletOption) => (
            <li
              key={option.name}
              className={(selectedValue ? option.id===selectedValue?.id : option.id === options[0].id) ? `${s.option} ${s.selectedOption}` : `${s.option}`}
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default WalletsSelect
