import { ReactComponent as CircularLoaderIcon } from 'assets/svg/CircularLoader.svg'
import s from './CircularLoader.module.scss'

interface Props {
  size?: number
  className?: string
  strokeColor?: string
}

const CircularLoader = ({ size = 44, className, strokeColor }: Props) => {
  return (
    <span
      className={`${className} ${s.container}`}
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <CircularLoaderIcon
        style={{ stroke: strokeColor || 'var(--color-primary)' }}
      />
    </span>
  )
}

export default CircularLoader
