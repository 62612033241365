import {useEffect, useMemo, useState} from 'react'
import PaperHeader from 'components/PaperHeader'
import Paper from 'components/Paper'
import Typography from 'components/Typography'
import Button from 'components/Button'
import FileDropzone from 'components/FileDropzone'

import { PageHeading } from 'assets/const'
import { useMatchMedia } from 'hooks/useMatchMedia'
import {useCreateKYC, useMangoKycInfo} from '../../../hooks/data/useMangoApi';
import {useUserProfile} from '../../../hooks/data/useUserProfile';
import {StatusKYC, UserMangoStatusKYCText} from '../../../types/mango';
import {MangoService} from '../../../services/mango.service';
import s from './MangoKyc.module.scss'
import {useSseStore} from '../../../store/useSseStore';
import Preloader from '../../../components/Preloader';
import {toast} from 'react-toastify';

const uploadDocsCount = 5;

const MangoKyc = () => {
    const { isTablet } = useMatchMedia()
    const [files, setFiles] = useState<File[]>([])
    const [pageNum, setPageNum] = useState<number>(1)
    const [fileErrors, setFileErrors] = useState<string[] | null>(null)
    const {mutate: startVerificationProcess} = useCreateKYC()
    const {mangoKycInfo, setMangoKycInfo} = useSseStore()
    const { data: profile, isLoading: isProfileLoading } = useUserProfile()
    const mangoProfile = profile?.businessSettings?.paymentSettings?.mango;
    const mangoKYCStatusWithProfile = mangoProfile?.kycStatus
    const [isLoadingAfterSubmit, setIsLoadingAfterSubmit] = useState(false)
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(true)
    const {mutate: startMangoKycQuery} = useMangoKycInfo()

    const canShowUploadButton = files.length < uploadDocsCount
    const canShowStartSession = !mangoKycInfo?.mangoKycDocumentId ||
        mangoKYCStatusWithProfile === StatusKYC.KYC_NOT_VERIFIED ||
         mangoKYCStatusWithProfile === StatusKYC.KYC_OUTDATED ||
         mangoKYCStatusWithProfile === StatusKYC.KYC_FAILED


    const removePdfFile = (file: File) => {
        setFiles(files!.filter((pdfFile) => pdfFile !== file))
        setFileErrors(null)
        setPageNum(pageNum-1)
    }

    const uploadFiles = (file: File[] | null) => {
        setDisabledSubmitButton(true)
        if (file?.length && mangoKycInfo?.mangoKycDocumentId) {
            const reader = new FileReader();
            reader.readAsDataURL(file[file.length-1]);
            reader.onloadend = () => {
                const base64String = reader.result as string;
                MangoService.pageKYC({
                    mangoKycDocumentId: mangoKycInfo.mangoKycDocumentId,
                    file: base64String,
                    pageNum: pageNum
                })
            };

            // console.log('uploadFiles', file, files, pageNum)
            setFiles(file)
            setPageNum(pageNum+1)
        }
    }

    useEffect(() => {
        if (mangoKYCStatusWithProfile === StatusKYC.KYC_VALIDATION_ASKED) {
            setIsLoadingAfterSubmit(false)
        }

        if (mangoKYCStatusWithProfile === StatusKYC.KYC_UNSUPPORTED_FILE_TYPE) {
            toast.error('Unsupported error file mango')
            setDisabledSubmitButton(false)
            removePdfFile(files[files.length-1])
        }

        // для файлов ждем статут KYC_NOT_VERIFIED, типо статус что файл долетел до манго.  вопросы к беку
        if (!!files.length && mangoKYCStatusWithProfile === StatusKYC.KYC_NOT_VERIFIED) {
            setDisabledSubmitButton(false)
        }
    }, [mangoProfile]);

    useEffect(() => {
        if (!files.length) {
            setDisabledSubmitButton(true)
        }
    }, [files]);

    useEffect(() => {
        if (!mangoKycInfo?.mangoKycDocumentId && mangoKYCStatusWithProfile === StatusKYC.KYC_CREATED) {
            startMangoKycQuery()
        }
    }, [mangoKYCStatusWithProfile]);

    const handleDocumentsUpload = () => {
        setIsLoadingAfterSubmit(true)
        MangoService.submitKYC(mangoKycInfo?.mangoKycDocumentId)
        // console.log(files)
    }

    const getTxtColorCls = useMemo(() => {
        let color : string | undefined;
        switch (mangoKYCStatusWithProfile) {
            case StatusKYC.KYC_NOT_VERIFIED:
            case StatusKYC.KYC_FAILED:
            case StatusKYC.KYC_OUTDATED:
                color = s.clrFailed
                break;
            case StatusKYC.KYC_SUCCEEDED:
                color = s.clrSuccess
                break;
            case StatusKYC.KYC_SUCCEEDED:
                color = s.clrSuccess
                break
            case StatusKYC.KYC_VALIDATION_ASKED:
                color = s.clrPending
                break

            default:
                color = s.clrDflt
                break
        }
        return color
    }, [mangoKYCStatusWithProfile])

    if (isProfileLoading) {
        return (
            <Preloader/>
        )
    }

    return (
        <Paper>
            <PaperHeader text={PageHeading.AccountVerification} />

            <Typography variant='h3' className={s.pdy10}>
                {`${profile?.firstName} ${profile?.lastName}`}
            </Typography>

            {/*common info status*/}
            {!!mangoKYCStatusWithProfile &&
                <div className={s.pdy10}> Status: <span className={`${getTxtColorCls}`}>
                    {UserMangoStatusKYCText[mangoKYCStatusWithProfile]}
                </span>
                    {/*<NotVerifiedIcon className={s.statusIcon} />*/}
                </div>}

            {/*start validation*/}
            { canShowStartSession && <div className={s.pdy10}>
                <Button disabled={false} onClick={startVerificationProcess} fullWidth={!isTablet}>
                    Start verification process
                </Button>
            </div>
            }

            {/*Created Step*/}
            {mangoKycInfo?.mangoKycDocumentId && (mangoKYCStatusWithProfile === StatusKYC.KYC_CREATED ||
                mangoKYCStatusWithProfile === StatusKYC.KYC_NOT_VERIFIED || mangoKYCStatusWithProfile === StatusKYC.KYC_UNSUPPORTED_FILE_TYPE) && <>
                <Typography variant='h5'>
                    Please upload your documents. The file size must not exceed 10 MB. Format jpg, png, pdf.
                </Typography>

                <FileDropzone
                    files={files}
                    setFiles={uploadFiles}
                    setFileErrors={setFileErrors}
                    fileErrors={fileErrors}
                    action={removePdfFile}
                    multiple={false}
                    maxSize={10000000}
                    minSize={32000}
                    showUploadButton={canShowUploadButton}
                />

                <Button disabled={disabledSubmitButton} onClick={handleDocumentsUpload} fullWidth={!isTablet}
                        loading={isLoadingAfterSubmit}>
                    Send
                </Button>
            </>}
        </Paper>
    )
}

export default MangoKyc
