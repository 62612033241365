import { ComponentProps } from 'react'
import s from './Typography.module.scss'

type ValidTagNames = 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

type TypographyOwnProps<E extends ValidTagNames> = {
  as?: E
  variant?: ValidTagNames,
  color?: string
}

type TypographyProps<E extends ValidTagNames> = TypographyOwnProps<E> &
  ComponentProps<E>

const defaultElement = 'p'

const Typography = <E extends ValidTagNames = typeof defaultElement>({
  children,
  color,
  as,
  className,
  variant,
  ...rest
}: TypographyProps<E>) => {
  const Component = as || defaultElement
  const styles = variant || defaultElement

  return (
    <Component
      className={`${s.heading} ${s[styles]} ${className ?? ''}`}
      {...rest}
    >
      {children}
    </Component>
  )
}

export default Typography
