import { api } from 'api/api'
import {
    CreateMangoLegalProfileRequest,
    MangoLegalProfile,
    MangoNaturalProfile,
    MangoCardRequest,
    MangoDictionary,
    MangoKYCRequest,
    PayinMangoCardRequest
} from 'types/mango';
import {uuid} from 'utils/uuid';
import {SelectOption} from 'components/CustomInputSelect';

const prepareMangoDictionaryData = (arr: [string]): SelectOption[] => {
    return arr.map(item => {
        return {
            label: item.replaceAll('_', ' / '),
            value: item
        }
    })
}
export const transformMangoDictionary = (data: any) :MangoDictionary => {
 return {
     clientId: data.clientId,
     cardTypeList: prepareMangoDictionaryData(data.cardTypeList),
     currencyList: prepareMangoDictionaryData(data.currencyList),
 }
}
export const MangoService = {
    async getDict(): Promise<MangoDictionary> {
        return api(`mango/dict`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
                    .then(data => transformMangoDictionary(data))
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async createMangoNaturalPayer() {
      return api('mango/natural/create', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
  },

    async createMangoCard(payload: MangoCardRequest) {
        return api('mango/card/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({...payload, sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async payinMangoCard(payload: PayinMangoCardRequest) {
        return api('mango/card/payin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({...payload, sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async getMangoPaymentInfo(invoiceId: string) {
        return api(`mango/payment/info?invoiceId=${invoiceId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    // --------mango owner------
    async createMangoNaturalProfile() {
      return api(`mango/natural/create`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
  },

    async createMangoLegalProfile(payload: CreateMangoLegalProfileRequest) {
      return api(`mango/legal/create`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({...payload, sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
    },

    async editMangoNaturalProfile(payload: MangoNaturalProfile) {
      return api(`mango/natural/edit`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({...payload, sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
    },

    async editMangoLegalProfile(payload: MangoLegalProfile) {
      return api(`mango/legal/edit`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({...payload, sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
    },

    async getMangoCountriesSse() {
      return api(`mango/countries`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
    },

    async getMangoNaturalProfileSse() {
      return api(`mango/natural`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
    },

    async getMangoLegalProfileSse() {
      return api(`mango/legal`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ sessionId: uuid }),
      }).then(async (r) => {
          if (r.ok) {
              return r.json()
          } else {
              return r.json().then((err) => Promise.reject(err))
          }
      })
    },

    // --------kyc area------
    async createKYC() {
        return api(`mango/kyc/create`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async pageKYC(data: MangoKYCRequest) {
        const queryParams = new URLSearchParams({
            mangoKycDocumentId: data.mangoKycDocumentId,
            pageNum:  String(data.pageNum),
            sessionId: uuid
        }).toString()
        return api('mango/kyc2/page?' + queryParams, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data.file,
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async submitKYC(mangoKycDocumentId: string | undefined) {
        return api(`mango/kyc/submit`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({mangoKycDocumentId, sessionId: uuid }),
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },

    async getKycInfo() {
        return api(`mango/kyc/info`, {
            // method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(async (r) => {
            if (r.ok) {
                return r.json()
            } else {
                return r.json().then((err) => Promise.reject(err))
            }
        })
    },
}
