import { api } from '../api/api'
import { BankFormFields } from '../types/external-account'
import { uuid } from '../utils/uuid'
import { ResponseSse } from '../types/response'

export const ExternalBankAccountService = {
  async create(payload: BankFormFields): Promise<ResponseSse> {
    return api(`user/stripe/external/bank_account/create`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payload, sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async edit(payload: BankFormFields): Promise<ResponseSse> {
    return api(`user/stripe/external/bank_account/update`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payload, sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async delete(id: string): Promise<ResponseSse> {
    return api(`user/stripe/external/bank_account/delete`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async list(): Promise<ResponseSse> {
    return api(`user/stripe/external/bank_account/list`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },
}
