import { Outlet, useNavigate } from 'react-router-dom'

import { useReturnUrlfromSettings } from 'hooks/useReturnUrl'
import BackSectionLayout from 'layouts/BackSectionLayout'

import s from './Profile.module.scss'


const Profile = () => {
  const navigator = useNavigate()
  const { returnUrl } = useReturnUrlfromSettings()

  return (
    <main className={s.profileContainer}>
      <BackSectionLayout
        text='Back'
        path={returnUrl || ''}
        onClick={() => {
          if (!returnUrl) navigator(-1)
        }}
      >
        <Outlet />
      </BackSectionLayout>
    </main>
  )
}

export default Profile
