import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react'
import { To, useLocation, useNavigate } from 'react-router-dom'
import { IoIosArrowDown } from 'react-icons/io';
import { useUserProfile } from 'hooks/data/useUserProfile';

import {ReactComponent as SettingsIcon} from 'assets/svg/Settings.svg'
import {ReactComponent as StripeProfileIcon} from 'assets/svg/StripeProfile.svg'
import {ReactComponent as StripeIcon} from 'assets/svg/StripeIcon.svg'
import {ReactComponent as MangoIcon} from 'assets/svg/MangoIcon.svg'
import {ReactComponent as BankAccountIcon} from 'assets/svg/BankAccount.svg'
import { menuRoutes } from 'assets/const'

import s from './MobileMenu.module.scss'


type Option = { title: string; icon: ReactNode; path: string }

const options = {
  payment:
    {
      title: 'Payment settings',
      icon: <SettingsIcon color='var(--color-primary)'/>,
      path: menuRoutes.Payment,
    },
  stripeProfile:
    {
      title: 'Stripe profile',
      icon: <StripeProfileIcon color='var(--color-primary)'/>,
      path: menuRoutes.StripeProfile,
    },
  stripeExternalBankAccount:
    {
      title: 'External bank account',
      icon: <BankAccountIcon color='var(--color-primary)'/>,
      path: menuRoutes.StripeBankAccounts,
    },
  mangoProfile:
    {
      title: 'Mango profile',
      icon: <StripeProfileIcon color='var(--color-primary)'/>,
      path: menuRoutes.MangoProfile,
    },
  mangoAccountVerification:
    {
      title: 'Account verification',
      icon: <BankAccountIcon color='var(--color-primary)'/>,
      path: menuRoutes.MangoVerification,
    },
    mangoExternalBankAccount:
    {
      title: 'External bank account',
      icon: <BankAccountIcon color='var(--color-primary)'/>,
      path: menuRoutes.MangoExternalBankAccount,
    },
}

const MobileMenu = () => {
  const { isMangoOwner } = useUserProfile()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [ openMenus, setOpenMenus ] = useState<('stripe' | 'mango')[]>([])
  const [selectOptions, setSelectOptions] = useState(
    Object.values(options).find((option) => pathname.endsWith(option.path)) || options.payment
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)

  useEffect(() => {
    setSelectOptions(Object.values(options).find((option) => pathname.endsWith(option.path)) || options.payment)
  }, [pathname])

  // const positionDropdown = () => {
  //   if (!dropdownRef.current || !containerRef.current) return
  // }

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const toggleMenu = (menuName: 'stripe' | 'mango') => {
    if (openMenus.includes(menuName)) {
      setOpenMenus(openMenus.filter(item => item !== menuName));
    } else {
      setOpenMenus([...openMenus, menuName]);
    }
  };

  const handleNavigate = (e: MouseEvent<HTMLLIElement>, path: To) => {
    e.stopPropagation()
    navigate(path)
    setIsOpen(false)
  }

  const isOptionSelected = (option: Option) => {
    return option.title === selectOptions?.title
  }

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.target !== containerRef.current) return
      switch (e.code) {
        case 'Enter':
        case 'Space':
          setIsOpen((prev) => !prev)
          // if (isOpen) navigate(options[highlightedIndex].path)
          break
        case 'ArrowUp':
        case 'ArrowDown': {
          if (!isOpen) {
            setIsOpen(true)
            break
          }

          // const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1)
          // if (newValue >= 0 && newValue < options.length) {
          //   setHighlightedIndex(newValue)
          // }
          break
        }
        case 'Escape':
          setIsOpen(false)
          break
      }
    }

    containerRef.current?.addEventListener('keydown', handler)

    return () => {
      containerRef.current?.removeEventListener('keydown', handler)
    }
  }, [highlightedIndex, isOpen, options])

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0)
  }, [isOpen])

  return (
    <div className={s.wrapper}>
      <div
        ref={containerRef}
        onBlur={(e) => setIsOpen(false)}
        onClick={toggleDropdown}
        tabIndex={0}
        className={`${s.container} ${isOpen ? s.active : ''}`}
        aria-haspopup='listbox'
        role='button'
      >
        {selectOptions && (
          <div className={`${s.infoSelected} ${s.infoItem}`}>
            <SettingsIcon color='var(--color-primary)'/>
            <span>{selectOptions.title}</span>
            <div className={s.arrowWrapper}>
              <IoIosArrowDown
                fontSize={16}
                style={{
                  transform: isOpen ? 'rotate(180deg)' : 'none',
                  transition: 'transform .2s',
                  color: 'var(--color-primary)',
                }}
              />
            </div>
          </div>
        )}
        <ul
          ref={dropdownRef}
          className={`${s.options} ${isOpen ? s.show : ''}`}
          role='listbox'
        >
          <li
            className={`${s.option} ${isOptionSelected(options.payment) ? s.selected : ''}`}
            // ${index === highlightedIndex ? s.highlighted : ''
            // onMouseEnter={() => setHighlightedIndex(index)}
            onClick={(e) => handleNavigate(e, options.payment.path)}

          >
            <div className={s.infoItem}>
              {options.payment.icon}
              <span>{options.payment.title}</span>
            </div>
          </li>

          <li
            className={`${s.option} ${s.dropButton}`}
            onClick={(e) => {
              e.stopPropagation()
              toggleMenu('stripe')}
            }
          >
            <div className={s.infoItem}>
              <StripeIcon/>
              <span>Stripe</span>
            </div>
            <IoIosArrowDown
              style={{
                rotate: openMenus.includes('stripe') ? '180deg' : '0deg',
                transition: 'rotate .3s'
              }}
            />
          </li>

          <ul className={s.dropList} style={{ maxHeight: openMenus.includes('stripe') ? '124px' : '0px', transition: 'all .2s linear'}}>
            <li
                className={`${s.option} ${isOptionSelected(options.stripeProfile) ? s.selected : ''}`}
                onClick={(e) => handleNavigate(e, options.stripeProfile.path)}
              >
                <div className={s.infoItem}>
                  {options.stripeProfile.icon}
                  <span>{options.stripeProfile.title}</span>
                </div>
              </li>
              <li
                className={`${s.option} ${isOptionSelected(options.stripeExternalBankAccount) ? s.selected : ''}`}
                onClick={(e) => handleNavigate(e, options.stripeExternalBankAccount.path)}
              >
                <div className={s.infoItem}>
                  {options.stripeExternalBankAccount.icon}
                  <span>{options.stripeExternalBankAccount.title}</span>
                </div>
              </li>
            </ul>

          <li
            className={`${s.option} ${s.dropButton}`}
            onClick={(e) => {
              e.stopPropagation()
              toggleMenu('mango')}
            }
          >
            <div className={s.infoItem}>
              <MangoIcon color='var(--color-primary)'/>
              <span>Mango</span>
            </div>
            <IoIosArrowDown
              style={{
                rotate: openMenus.includes('mango') ? '180deg' : '0deg',
                transition: 'rotate .3s'
              }}
            />
          </li>

          <ul className={s.dropList} style={{ maxHeight: openMenus.includes('mango') ? '186px' : '0px', transition: 'all .2s linear'}}>
            <li
              className={`${s.option} ${isOptionSelected(options.mangoProfile) ? s.selected : ''}`}
              onClick={(e) => handleNavigate(e, options.mangoProfile.path)}
            >
              <div className={s.infoItem}>
                {options.mangoProfile.icon}
                <span>{options.mangoProfile.title}</span>
              </div>
            </li>
            {isMangoOwner &&
            <>
              <li
                className={`${s.option} ${isOptionSelected(options.mangoAccountVerification) ? s.selected : ''}`}
                onClick={(e) => handleNavigate(e, options.mangoAccountVerification.path)}
              >
                <div className={s.infoItem}>
                  {options.mangoAccountVerification.icon}
                  <span>{options.mangoAccountVerification.title}</span>
                </div>
              </li>

              <li
                className={`${s.option} ${isOptionSelected(options.mangoExternalBankAccount) ? s.selected : ''}`}
                onClick={(e) => handleNavigate(e, options.mangoExternalBankAccount.path)}
              >
                <div className={s.infoItem}>
                  {options.mangoExternalBankAccount.icon}
                  <span>{options.mangoExternalBankAccount.title}</span>
                </div>
              </li>
            </>
            }
          </ul>

        </ul>
      </div>
    </div>
  )
}
export default MobileMenu
