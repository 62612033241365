import { Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import Footer from 'components/Footer'
import Header from 'components/Header'
import Preloader from 'components/Preloader'
import Sidebar from 'components/Sidebar'
import MobileMenu from 'pages/Profile/MobileMenu'

import { useMatchMedia } from 'hooks/useMatchMedia'

import s from './MainLayout.module.scss'


const MainLayout = () => {
  const { isLaptop } = useMatchMedia()
  const {pathname} = useLocation()
  const isSettings = pathname.startsWith('/settings')

  return (
    <div className={s.rootContainer}>
      <Header withLogo={!isSettings}/>
      <main className={s.mainContainer}>
        {isSettings && (isLaptop ? <Sidebar/> : <MobileMenu/>)}
        <div className={s.content}>
        <Suspense fallback={<Preloader />}>
          <Outlet />
        </Suspense>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default MainLayout
