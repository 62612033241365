import { useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Button from 'components/Button'
import CustomSelect from 'components/CustomSelect'
import Preloader from 'components/Preloader'
import TextField from 'components/TextField'

import { UserService } from 'services/user.service'
import { useSseStore } from 'store/useSseStore'
import { StripeAccount, UserProfile } from 'types/user-profile'

import CustomInputSelect from 'components/CustomInputSelect'
import s from './StripeCreateForm.module.scss'

interface Props {
  userData: UserProfile
  isUpdatingSse: boolean
  setIsUpdatingSse: (value: boolean) => void
  onCancelClick: () => void
}

type SelectOption = {
  label: string
  value: string
}

type FormFields = {
  firstName: string
  lastName: string
  email: string
  businessName: string
}

const businessOptions: SelectOption[] = [
  { label: 'Individual', value: 'individual' },
  { label: 'Company', value: 'company' },
]

const StripeCreateForm = ({
  userData,
  isUpdatingSse,
  setIsUpdatingSse,
  onCancelClick,
}: Props) => {
  const { countries } = useSseStore()
  const [country, setCountry] = useState<SelectOption | undefined>(undefined)
  const [businessType, setBusinessType] = useState<SelectOption | undefined>(
    businessOptions[0]
  )

  const {
    register,
    handleSubmit,
    resetField,
    control,
    formState: { errors, isValid },
  } = useForm<FormFields>({
    values: {
      firstName: userData?.firstName ?? '',
      lastName: userData?.lastName ?? '',
      email: userData?.email ?? '',
      businessName: '',
    },
    mode: 'onBlur',
  })

  const countryOptions = useMemo(() => {
    if (!countries) return null
    return countries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [countries])

  const handleBusinessChange = (option: SelectOption | undefined) => {
    if (option?.value === 'individual') {
      resetField('businessName', {
        keepDirty: true,
        keepTouched: true,
      })
    }
    setBusinessType(option)
  }

  const handleCityChange = (option: SelectOption | undefined) => {
    setCountry(option)
  }

  const onSubmit: SubmitHandler<FormFields> = (formData) => {
    if (!country) {
      return
    }

    if (!businessType) {
      return
    }

    const stripeAccountPayload: StripeAccount = {
      businessType: businessType.value,
      firstName: formData.firstName,
      lastName: formData.lastName,
      country: country.value,
      email: formData.email,
      companyName: formData.businessName,
      refreshUrl: window.location.href,
      returnUrl: window.location.href,
      ip: '',
    }
    UserService.createStripeAccountSse(stripeAccountPayload).then(() => {
      setIsUpdatingSse(true)
    })
  }

  if (!countryOptions) {
    return (
      <div className={s.preloader}>
        <Preloader />
      </div>
    )
  }

  return (
    <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        register={register('firstName', {
          required: 'This field is required',
        })}
        label='First name*'
        type='text'
        autoComplete='off'
        error={errors.firstName?.message}
      />
      <TextField
        register={register('lastName', {
          required: 'This field is required',
        })}
        label='Last name*'
        type='text'
        autoComplete='off'
        error={errors.lastName?.message}
      />
      <TextField
        register={register('email', {
          required: 'This field is required',
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Invalid email address',
          },
        })}
        label='Email*'
        type='email'
        autoComplete='off'
        error={errors.email?.message}
      />
      <CustomInputSelect
        required
        options={countryOptions || []}
        onChange={handleCityChange}
        value={country}
        label='Country'
      />
      <CustomSelect
        options={businessOptions}
        name='businessType'
        control={control}
        label='Business type'
      />
      {businessType?.value === 'company' && (
        <TextField
          register={register('businessName', {
            required: 'This field is required',
          })}
          label='Business name*'
          type='text'
          autoComplete='off'
          error={errors.businessName?.message}
        />
      )}

      <div className={s.buttonsContainer}>
        <Button
          disabled={!isValid || !country || !businessType || isUpdatingSse}
          type='submit'
          fullWidth
        >
          Create Stripe Account
        </Button>
        <Button variant='outlined' fullWidth onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default StripeCreateForm
