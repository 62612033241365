import { NavLink } from 'react-router-dom';
import { useState } from 'react';

import { menuRoutes } from 'assets/const';
import { IoIosArrowDown } from 'react-icons/io';
import { useUserProfile } from 'hooks/data/useUserProfile';

import { ReactComponent as SettingsIcon } from 'assets/svg/Settings.svg'
import { ReactComponent as StripeIcon } from 'assets/svg/StripeIcon.svg'
import { ReactComponent as MangoIcon } from 'assets/svg/MangoIcon.svg'
import { ReactComponent as StripeProfileIcon } from 'assets/svg/StripeProfile.svg'
import { ReactComponent as BankAccountIcon } from 'assets/svg/BankAccount.svg'
import { ReactComponent as LogoTitle } from 'assets/svg/CWLogoTitle.svg'
import { ReactComponent as LogoIcon } from 'assets/svg/CWLogoIcon.svg'

import s from './Sidebar.module.scss'


const Sidebar = () => {

  const { isMangoOwner } = useUserProfile()
  const [ openMenus, setOpenMenus ] = useState<('stripe' | 'mango')[]>([])
  const toggleMenu = (menuName: 'stripe' | 'mango') => {
    if (openMenus.includes(menuName)) {
      setOpenMenus(openMenus.filter(item => item !== menuName));
    } else {
      setOpenMenus([...openMenus, menuName]);
    }
  };

  return (
    <div className={s.sidebar}>
      <div className={s.logoWrapper}>
        <LogoTitle color='var(--color-contrast-text)'/>
        <LogoIcon color='var(--color-contrast-text)'/>
      </div>
      <div className={s.menu}>
        <NavLink
          to={menuRoutes.Payment}
          className={({ isActive }) =>
            isActive ? `${s.active} ${s.link}` : s.link
          }
        >
          <div className={s.infoItem}>
            <SettingsIcon className={s.icon} />
            <span>Payment settings</span>
          </div>
        </NavLink>

        <div className={`${s.infoItem} ${s.dropdown}`}  onClick={() => toggleMenu('stripe')}>
          <StripeIcon className={s.icon} />
          <span className={s.dropbtn}>Stripe</span>
          <IoIosArrowDown
            style={{
              rotate: openMenus.includes('stripe') ? '180deg' : '0deg',
              transition: 'rotate .3s'
            }}
          />
        </div>

        <div className={s.dropdownContent} style={{ maxHeight: openMenus.includes('stripe') ? '124px' : '0px', transition: 'all .2s linear'}}>
          <NavLink
            to={menuRoutes.StripeProfile}
            className={({ isActive }) =>
              isActive ? `${s.active} ${s.link}` : s.link
            }
          >
            <div className={`${s.infoItem} ${s.dropInfoitem}`}>
              <StripeProfileIcon className={s.icon} />
              <span>Stripe profile</span>
            </div>
          </NavLink>

          <NavLink
            to={menuRoutes.StripeBankAccounts}
            className={({ isActive }) =>
              isActive ? `${s.active} ${s.link}` : s.link
            }
          >
            <div className={`${s.infoItem} ${s.dropInfoitem}`}>
              <BankAccountIcon className={s.icon} />
              <span>External bank account</span>
            </div>
          </NavLink>
        </div>

        <div className={`${s.infoItem} ${s.dropdown}`}  onClick={() => toggleMenu('mango')}>
          <MangoIcon className={s.icon} />
          <span className={s.dropbtn}>Mango</span>
          <IoIosArrowDown
            style={{
              rotate: openMenus.includes('mango') ? '180deg' : '0deg',
              transition: 'rotate .3s'
            }}
          />
        </div>

        <div className={s.dropdownContent} style={{ maxHeight: openMenus.includes('mango') ? '186px' : '0px', transition: 'all .2s linear'}}>
          <NavLink
            to={menuRoutes.MangoProfile}
            className={({ isActive }) =>
              isActive ? `${s.active} ${s.link}` : s.link
            }
          >
            <div className={`${s.infoItem} ${s.dropInfoitem}`}>
              <StripeProfileIcon className={s.icon} />
              <span>Mango profile</span>
            </div>
          </NavLink>

          {isMangoOwner &&
          <>
            <NavLink
              to={menuRoutes.MangoVerification}
              className={({ isActive }) =>
                isActive ? `${s.active} ${s.link}` : s.link
              }
            >
              <div className={`${s.infoItem} ${s.dropInfoitem}`}>
                <BankAccountIcon className={s.icon} />
                <span>Account verification</span>
              </div>
            </NavLink>

            <NavLink
              to={menuRoutes.MangoExternalBankAccount}
              className={({ isActive }) =>
                isActive ? `${s.active} ${s.link}` : s.link
              }
            >
              <div className={`${s.infoItem} ${s.dropInfoitem}`}>
                <BankAccountIcon className={s.icon} />
                <span>External bank account</span>
              </div>
            </NavLink>
          </>
          }
        </div>
      </div>
    </div>
  )
}

export default Sidebar