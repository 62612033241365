import { api } from 'api/api'
import { MangoBankAccountCaRequest, MangoBankAccountGbRequest, MangoBankAccountIbanRequest, MangoBankAccountOtherRequest, MangoBankAccountUsRequest } from 'types/mango';
import {uuid} from 'utils/uuid';
import { ResponseSse } from 'types/response';
import { toastError } from 'utils/toast';


export const MangoExternalBankAccountsService = {
  async getList(): Promise<ResponseSse> {
    return api(`mango/external/bank-accounts?sessionId=${uuid}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(async (r) => {
      if (r.ok) {
        return r.json();
      } else {
        return r.json().then((err) => Promise.reject(err));
      }
    })
    .catch((err) => {
      toastError(err)
    })
  },

  async getDetails(bankAccountId: string): Promise<ResponseSse> {
    return api(`mango/external/bank-account/${bankAccountId}?sessionId=${uuid}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }).then(async (r) => {
      if (r.ok) {
        return r.json();
      } else {
        return r.json().then((err) => Promise.reject(err));
      }
    })
    .catch((err) => {
      toastError(err)
    })
  },

  async delete(bankAccountId: string): Promise<ResponseSse> {
    return api(`mango/external/bank-account/${bankAccountId}/deactivate?sessionId=${uuid}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
    .catch((err) => {
      toastError(err)
    })
  },

  async createIban(payload: MangoBankAccountIbanRequest): Promise<ResponseSse> {
    return api(`mango/external/bank-account/create/iban`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payload, sessionId: uuid }),
    })
    .then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async createUs(payload: MangoBankAccountUsRequest): Promise<ResponseSse> {
    return api(`mango/external/bank-account/create/us`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payload, sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async createCa(payload: MangoBankAccountCaRequest): Promise<ResponseSse> {
    return api(`mango/external/bank-account/create/ca`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payload, sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async createGb(payload: MangoBankAccountGbRequest): Promise<ResponseSse> {
    return api(`mango/external/bank-account/create/gb`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payload, sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async createOther(payload: MangoBankAccountOtherRequest): Promise<ResponseSse> {
    return api(`mango/external/bank-account/create/other`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payload, sessionId: uuid }),
    }).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },
}
