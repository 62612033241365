import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import Typography from 'components/Typography';
import { ReactComponent as CloseIcon } from 'assets/svg/Close.svg';
import s from './AppModal.module.scss';

interface Props {
  onClose: () => void;
  title: string;
  children: any;
}

const AppModal = ({ onClose, title, children }: Props) => {
  useEffect(() => {
    const containerElement = document.querySelector(`.${s.container}`);
    if (containerElement) {
      containerElement.scrollTop = 0;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && (document.activeElement instanceof HTMLInputElement || document.activeElement instanceof HTMLSelectElement)) {
        e.preventDefault()
        e.stopPropagation()
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {createPortal(
        <div className={s.container}>
          <div
            role='dialog'
            className={s.modal}
            onClick={(e) => e.stopPropagation()}
          >
            <CloseIcon className={s.closeIcon} onClick={onClose} />
            <Typography variant="h3" className={s.title}>
              {title}
            </Typography>
            <div className={s.content}>{children}</div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
};

export default AppModal;
