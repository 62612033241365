import { toast } from 'react-toastify'

export const errCatch = (error: any): string => {
  return typeof error === 'object'
    ? formatMessage(error?.reason || error?.message)
    : error.toString()
}

export const toastError = (error: any, message?: string, toastId?: string) => {
  const errorMessage = errCatch(error)
  toast.error(message || errorMessage, { toastId })
}

const formatMessage = (message: string) => {
  const index = message.indexOf(':')
  return index === -1 ? message : message.slice(index + 1).trim()
}
