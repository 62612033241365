import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

export const formatTime = (milliseconds: number): string => {
  return dayjs.duration(milliseconds, 'milliseconds').format('HH:mm:ss')
}

export const getTimeStump = () => dayjs().unix() * 1000

export const getTime = (milliseconds?: number): string => {
  if (milliseconds) {
    return dayjs(milliseconds * 1000).format()
  }
  return dayjs().format()
}
