import { AppRoute, PageHeading } from 'assets/const'
import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Preloader from 'components/Preloader'
import { usePath } from 'hooks/usePath'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const StripeComplete = () => {
  const DELAY = 5000
  const path = usePath(`/${AppRoute.Settings}/${AppRoute.Stripe}/${AppRoute.Profile}`)
  const navigate = useNavigate()

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate(path)
    }, DELAY)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Paper textAlign='center'>
      <PaperHeader text={PageHeading.StripeProfile} />
      <div style={{ position: 'relative', minHeight: '400px' }}>
        <Preloader />
      </div>
    </Paper>
  )
}

export default StripeComplete
