import { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import Typography from 'components/Typography'

import s from './Popup.module.scss'


export interface Props {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  title?: string
}

const Popup = ({ children, onClose, isOpen, title }: Props) => {
  ///wip
  return (
    <>
      {createPortal(
        <div className={`${s.container} ${isOpen ? s.open : s.close}`}>
          {isOpen && (
            <div
              role='dialog'
              className={s.modal}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {title && <Typography variant='h3'>{title}</Typography>}
              {children}
              {/*<Button fullWidth onClick={onClose}>*/}
              {/*  Close*/}
              {/*</Button>*/}
            </div>
          )}
        </div>,
        document.body
      )}
    </>
  )
}

export default Popup
