import { Navigate, Outlet } from 'react-router-dom'

import { AppRoute } from 'assets/const'
import { usePath } from 'hooks/usePath'
import { useToken } from 'hooks/useToken'
import { toastError } from 'utils/toast'

const PrivateTokenRoute = () => {
  const errorPath = usePath(`/${AppRoute.Error}`)
  const { token } = useToken()

  if (!token) {
    toastError('', 'Token required', token)
    return <Navigate to={errorPath} />
  }

  return <Outlet />
}

export default PrivateTokenRoute
