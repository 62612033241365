import s from './StatusLabel.module.scss'

interface Props {
  status: string
}

const StatusLabel = ({ status }: Props) => {
  return <span className={`${s.label} ${s[status]}`}>{status}</span>
}

export default StatusLabel
