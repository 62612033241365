import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakLogoutOptions,
} from 'keycloak-js'

const keycloakInitOptions: KeycloakInitOptions = {
  checkLoginIframe: false,
  onLoad: 'login-required',
}

export let kc: Keycloak

const initKeycloak = (
  onAuthenticatedCallback: () => void,
  keycloakConfig: KeycloakConfig
) => {
  if (!keycloakConfig) {
    throw new Error('keycloakConfig is required')
  }

  kc = new Keycloak(keycloakConfig)

  kc.init(keycloakInitOptions)
    .then((authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback()
      } else {
        console.log('user is not authenticated..!')
      }
    })
    .catch((error) => {
      console.error('Error initializing keycloak:', error)
      throw error
    })
}

const doLogin = () => kc.login()

const doLogout = (options?: KeycloakLogoutOptions) => kc.logout(options)

const getAccountUrl = () => kc.createAccountUrl()

const getToken = () => kc.token

const isLoggedIn = () => kc.authenticated

const updateToken = async () => await kc.updateToken(10).catch()

const getUserEmail = () => kc.tokenParsed?.email

const shouldUpdateToken = () => kc.isTokenExpired(300)

const updateTokenSse = async (timeout: number) =>
  await kc
    .updateToken(timeout)
    .then(() => getToken())
    .catch(() => '')

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUserEmail,
  shouldUpdateToken,
  getAccountUrl,
  updateTokenSse,
}

export default AuthService
