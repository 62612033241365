import { UserProfile } from 'types/user-profile'
import { humanizeBoolean } from 'utils/text'

import s from 'components/StripeProfileForm/StripeProfileForm.module.scss'


interface Props {
  userData: UserProfile
}

const MangoProfileInfo = ({ userData }: Props) => {
  const mangoSettings = userData.businessSettings.paymentSettings.mango
  const IsIndividual = userData.businessSettings.paymentSettings.mango.type === 'NATURAL'

  return (
      <div className={s.profileInfoWrapper}>
        <div className={s.profileItem}>
          <div className={s.label}>{IsIndividual ? 'First Name:' : 'Company Name:'}</div>
          <div className={s.value}>{IsIndividual ? userData.firstName : mangoSettings.businessName}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>{IsIndividual ? 'Last Name:' : 'Type'}</div>
          <div className={s.value}>{IsIndividual ? userData.lastName : mangoSettings.legalPersonType}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Email:</div>
          <div className={s.value}>{userData.email}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>{IsIndividual ? 'Country of residence:' : 'Representative name:'}</div>
          <div className={s.value}>{IsIndividual ? (mangoSettings.country || '-') : `${userData.firstName} ${userData.lastName}`}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Mango available:</div>
          <div className={s.value}>
            {humanizeBoolean(mangoSettings.available)}
          </div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Mango profile id:</div>
          <div className={s.value}>{mangoSettings.payerId || mangoSettings.ownerId}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Payouts:</div>
          <div className={s.value}>
            {humanizeBoolean(mangoSettings.canPayout)}
          </div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Payments:</div>
          <div className={s.value}>
            {humanizeBoolean(!!mangoSettings.payerId || !!mangoSettings.ownerId)}
          </div>
        </div>
    </div>
  )
}

export default MangoProfileInfo
