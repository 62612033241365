import {useGetMangoDict} from '../../hooks/data/useMangoApi';
import {useEffect, useRef, useState} from 'react';
import Preloader from '../../components/Preloader';
import {AppRoute} from '../../assets/const';
import Button from '../../components/Button';
import BackSectionLayout from '../../layouts/BackSectionLayout';
import Paper from '../../components/Paper';
import PaperHeader from '../../components/PaperHeader';
import {usePath} from '../../hooks/usePath';
import {useLocation} from 'react-router-dom';
import StyledLink from '../../components/StyledLink';
import s from './DictionaryStep.module.scss'
import CustomInputSelect, {SelectOption} from '../../components/CustomInputSelect';

const MangoDictionaryStep = () => {
    const rootPath = usePath(AppRoute.Root)
    const {data: mangoDict, isLoading, mutate} = useGetMangoDict()
    const [cardType, setCardType] = useState<SelectOption | undefined>(undefined)
    const [currency, setCurrency] = useState<SelectOption | undefined>({value:'EUR', label:'EUR'})

    const ref = useRef({})
    const disabled = !cardType || !currency

    useEffect(()=> {
        ref.current = { pathname: AppRoute.Mango.Root + AppRoute.Mango.Payment, search: location.search, state: {cardType, currency}}
    }, [cardType, currency])

    const location = useLocation()
    const path = usePath(AppRoute.Mango.Root + AppRoute.Mango.Payment)

    if (isLoading) {
        return <div style={{ position: 'relative', minHeight: '550px' }}>
            <Preloader />
        </div>
    }

    return (
        <BackSectionLayout text='Back to payment information' path={rootPath}>
            <Paper textAlign='center'>
                <>
                    <PaperHeader text="Choose currency and card type" />
                    <form>
                        <div className={s.container}>
                            <label className={s.label}>Please select card type</label>
                            <CustomInputSelect
                                options={mangoDict?.cardTypeList || []}
                                onChange={setCardType}
                                value={cardType}
                                label='Card Type List'
                            />
                            <br />
                            <label className={s.label}>Please select currency</label>
                            <CustomInputSelect
                                options={mangoDict?.currencyList || []}
                                onChange={setCurrency}
                                value={currency}
                                label='Currency'
                            />
                        </div>

                        <div className={s.buttonsContainer}>
                            {!disabled ?
                                <StyledLink
                                    to={path}
                                    state={{cardType, currency}}
                                    fullWidth
                                >
                                    <Button
                                        as='span' fullWidth asDisabled={disabled}
                                    >
                                        Continue
                                    </Button>
                                </StyledLink>
                            :
                                <Button
                                    as='button'
                                    fullWidth asDisabled={disabled}
                                    disabled={disabled}
                                >
                                    Continue
                                </Button>
                            }
                        </div>
                    </form>
                </>
            </Paper>
        </BackSectionLayout>
    )
}

export default MangoDictionaryStep