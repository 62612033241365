import { fetchApi } from 'api/fetch-retry-api'
import { baseApiUrl } from 'assets/const'
import { Configuration } from 'types/configuration'

export const ConfigurationService = {
  async getConfiguration(): Promise<Configuration> {
    return fetchApi(`${baseApiUrl}configuration`, {
      headers: { 'Content-Type': 'application/json' },
      retries: 3,
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
