import { Configuration } from 'types/configuration'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface AppState {
  configuration: Configuration | undefined
  setConfiguration: (payload: Configuration) => void
}

export const useAppStore = create<AppState>()(
  devtools((set, get: () => AppState) => ({
    configuration: undefined,
    setConfiguration: (payload) => set({ configuration: payload }),
  }))
)
