import { useCallback, useEffect, useMemo, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import Button from '../../../components/Button'
import CheckBox from '../../../components/CheckBox/CheckBox'
import CircularLoader from '../../../components/CircularLoader'
import CustomInputSelect from '../../../components/CustomInputSelect'
import TextField from '../../../components/TextField'
import Popup from 'components/Popup'

import { ExternalBankAccountService } from '../../../services/external-bank-account.service'
import { useSseStore } from '../../../store/useSseStore'
import { BankAccount, BankFormFields } from '../../../types/external-account'

import s from '../ExternalAccountsForm.module.scss'


interface Props {
  data: BankFormFields | BankAccount | null
  onClose: () => void
  isOpen: boolean
  title: string
}

export type SelectOption = {
  label: string
  value: string
}

const BankAccountPopup = ({ data, onClose, isOpen, title }: Props) => {
  const [country, setCountry] = useState<SelectOption | undefined>(undefined)
  const { countries } = useSseStore()

  const isEditMode = data !== null

  useEffect(() => {
    if (data?.country) {
      const findCountry = countries?.find(
        (country) => country.name === data?.country
      )
      if (findCountry) {
        const selectOptionCountry = {
          value: findCountry.code,
          label: findCountry.name,
        }
        setCountry(selectOptionCountry)
      }
    }
  }, [data, countries])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<BankFormFields>({
    values: {
      accountHolderName: data?.accountHolderName ?? '',
      accountNumber: data?.accountNumber ?? '',
      country: data?.country ?? '',
      routingNumber: data?.routingNumber ?? '',
      default: data?.default ?? '',
    },
    mode: 'onBlur',
  })

  const onSubmit: SubmitHandler<BankFormFields> = useCallback(
    (formData) => {
      if (!country) {
        return
      }

      if (isEditMode) {
        const toServer = {
          accountHolderName: formData.accountHolderName,
          id: data.id,
        }
        ExternalBankAccountService.edit(toServer).then(() => {
          toast.info(
            'Information for update external bank account passed. Waiting!'
          )
          onClose()
        })
      } else {
        ExternalBankAccountService.create({
          ...formData,
          country: country.value,
        }).then(() => {
          toast.info(
            'Information for adding external bank account passed. Waiting!'
          )
          onClose()
        })
      }
    },
    [isEditMode, country]
  )

  const handleCityChange = (option: SelectOption | undefined) => {
    setCountry(() => option)
  }

  const countryOptions = useMemo(() => {
    if (!countries) return null
    return countries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [countries])

  return (
    <Popup isOpen={isOpen} onClose={onClose} title={title}>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          register={register('accountHolderName', {
            required: 'This field is required',
          })}
          label='Account holder*'
          type='text'
          autoComplete='off'
          error={errors.accountHolderName?.message}
        />
        <TextField
          register={register('accountNumber', {
            required: 'This field is required',
          })}
          disabled={isEditMode}
          label='Account number*'
          type='text'
          autoComplete='off'
          error={errors.accountNumber?.message}
        />
        {!countryOptions?.length ? (
          <div className={s.country}>
            Country <CircularLoader />
          </div>
        ) : (
          <CustomInputSelect
            required
            disabled={isEditMode}
            options={countryOptions || []}
            onChange={handleCityChange}
            value={country}
            label='Country'
          />
        )}
        <TextField
          register={register('routingNumber', {
            required: 'This field is required',
          })}
          disabled={isEditMode}
          label='Routing*'
          type='text'
          autoComplete='off'
          error={errors.routingNumber?.message}
        />
        <div className={s.system}>
          <CheckBox label='default' register={register('default')} />
        </div>

        <div className={s.buttonsContainer}>
          <Button disabled={!isValid || !country} type='submit' >
            {isEditMode ? 'Edit' : 'Create'}
          </Button>
          <Button variant='outlined'  onClick={onClose}>
            Cancel
          </Button>
        </div>
      </form>
    </Popup>
  )
}

export default BankAccountPopup
