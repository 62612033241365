import Avatar from 'components/Avatar'
import SkeletonLoader from 'components/SkeletonLoader'
import Typography from 'components/Typography'

import { useUserProfile } from 'hooks/data/useUserProfile'
import { useMatchMedia } from 'hooks/useMatchMedia'

import s from './UserInfo.module.scss'


const UserInfo = () => {
  const { data, isLoading } = useUserProfile()
  const {isTablet} = useMatchMedia()

  if (isLoading) return <SkeletonLoader size='small' width={300} />
  if (!data) return null

  return (
    <div className={s.container}>
      <div className={s.avatar}>
        <Avatar
          email={data?.email}
          firstName={data?.firstName}
          lastName={data?.lastName}
        />
      </div>
      {isTablet &&
      <>
        <div className={s.name}>
          <Typography
            color='var(--color-gray)'
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color:'var(--color-gray)',
              lineHeight: 1.5
            }}
          >
            {data?.firstName}
          </Typography>
          <Typography
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color:'var(--color-gray)',
              lineHeight: 1.5
            }}
          >
            {data?.lastName}
          </Typography>
        </div>
        <div className={s.email}>
          <Typography variant='h6' style={{color: 'var(--color-dark-gray)'}}>
            {data?.email}
          </Typography>
        </div>
      </>
      }
    </div>
  )
}

export default UserInfo
