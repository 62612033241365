import { FC, useCallback } from 'react'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'

import calculateFileSize from 'utils/calculateFileSize'

import Typography from 'components/Typography'
import UploadedFile from 'components/FileDropzone/UploadedFile'

import { ReactComponent as AttachIcon } from 'assets/svg/AttachIcon.svg'

import s from './FileDropZone.module.scss'


interface FileDropzoneProps {
  files: File[] | null
  setFiles: (files: File[] | null) => void
  fileErrors: string[] | null
  setFileErrors: (errors: string[] | null) => void
  action: (file: File) => void
  acceptedFormats?: { [key: string]: string[] }
  maxSize?: number,
  minSize?: number,
  multiple?: boolean,
  showUploadButton?: boolean,
}

const FileDropzone: FC<FileDropzoneProps> = ({
  files,
  fileErrors,
  action,
  setFiles,
  setFileErrors,
  acceptedFormats={ 'application/pdf': ['.pdf'], 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
  maxSize=10485760,
  minSize=32000,
  multiple,
  showUploadButton = true
}) => {

  const onDropRejected = (fileRejections: FileRejection[]) => {
    setFileErrors(null)
    let errorsSet: Set<string> = new Set()
    if (fileRejections.length > 10) {
      errorsSet.add('Only 10 file can be added')
    }

    fileRejections.forEach((rejection) => {
      const isSizeError = rejection.errors.some((error) => error.code === ErrorCode.FileTooLarge)
      if (isSizeError) {
        errorsSet.add(`This file is larger than ${calculateFileSize(maxSize)}`)
      }

      const isSizeSmallError = rejection.errors.some((error) => error.code === ErrorCode.FileTooSmall)
      if (isSizeSmallError) {
        errorsSet.add(`This file is small than ${calculateFileSize(minSize)}`)
      }

      const isTypeError = rejection.errors.some((error) => error.code === ErrorCode.FileInvalidType)
      if (isTypeError) {
        errorsSet.add(
          'Only the following formats can be added: PDF, JPG, PNG',
        )
      }
    })

    setFileErrors(Array.from(errorsSet))
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFileErrors(null)
      setFiles( files ? [...files, ...acceptedFiles] : acceptedFiles)
    },
    [files],
  )

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: acceptedFormats,
    noClick: true,
    noKeyboard: true,
    maxFiles: 4,
    maxSize: maxSize,
    multiple,
    onDrop,
    onDropRejected,
  })

  return (
    <div className={s.container} >
      {files?.length && !fileErrors ?
        files.map((file, index) => (
          <UploadedFile key={file.name + index} file={file} action={action} />
        ))
        :
        null
      }
      <div>
        { showUploadButton && <div className={s.uploadFile} {...getRootProps()} onClick={open}>
          <input {...getInputProps()} />
          <AttachIcon/>
          <Typography variant='h5'>Upload documents</Typography>
        </div>}

        {fileErrors && (
          <div >
            {fileErrors.map((error, index) => (
              <Typography key={index} variant='h4' color='error'>
                {error}
              </Typography>
            ))}
          </div>
        )}
      </div>

    </div>
  )
}

export default FileDropzone