import useSWR from 'swr/immutable'

import { InvoiceStatus, SwrKey } from 'assets/const'
import { useToken } from 'hooks/useToken'
import { FinanceService } from 'services/finance.service'
import { Invoice } from 'types/invoice'

export const useInvoice = () => {
  const { token } = useToken()

  const invoice = useSWR<Invoice, Error>(
    token ? SwrKey.Invoice : null,
    () => FinanceService.getInvoice(token),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      revalidateIfStale: true,
    }
  )

  const cancelInvoice = async (invoiceId: string) => {
    return FinanceService.cancelInvoice(invoiceId)
  }

  return {
    cancelInvoice,
    ...invoice,
    isNewStatus: invoice.data?.status === InvoiceStatus.New,
    isProcessingStatus: invoice.data?.status === InvoiceStatus.Processing,
  }
}
