import { Link, LinkProps } from 'react-router-dom'
import s from './StyledLink.module.scss'

type Props = LinkProps & {
  fullWidth?: boolean
}

const StyledLink = ({ className, children, fullWidth, ...rest }: Props) => {
  return (
    <Link
      className={`${s.styledLink} ${fullWidth ? s.fullWidth : ''} ${className}`}
      {...rest}
    >
      {children}
    </Link>
  )
}

export default StyledLink
