import { FC } from 'react'

import Button from 'components/Button'
import Popup from 'components/Popup'

import s from './ConfirmPopup.module.scss'


interface Props {
  isOpen: boolean
  title: string
  onClose: () => void
  action: () => void
}

const ConfirmPopup: FC<Props> = ({isOpen, onClose, title, action}) => {

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <div className={s.container}>
        <span
          className={s.text}
        >{title}</span>
        <div className={s.buttonsRemoveBlock}>
          <Button onClick={action} type='button' fullWidth size='medium'>
            Yes
          </Button>
          <Button variant='outlined' fullWidth onClick={onClose}>
            No
          </Button>
        </div>
      </div>
    </Popup>
  )
}

export default ConfirmPopup