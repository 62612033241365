import { APP_VERSION } from 'assets/version'
import Typography from 'components/Typography'
import s from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={s.footerWrapper}>
      <Typography variant='h6' className={s.copyright}>
        Copyright ©{new Date().getFullYear()}
      </Typography>
      <Typography variant='h6'>
        CleverWallet LLC. All rights reserved.Version: {APP_VERSION}
      </Typography>
    </footer>
  )
}

export default Footer
