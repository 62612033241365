import useSWR from 'swr/immutable'
import { SwrKey } from 'assets/const'
import {ExternalBankAccountService} from '../../../services/external-bank-account.service';
import {BankFormFields} from '../../../types/external-account';
import {UserService} from '../../../services/user.service';
import {getLocale} from '../../../utils/intl';
import {ResponseSse} from '../../../types/response';

export const useCreateBankAcccount = () => {
    return useSWR<ResponseSse, Error>(
        SwrKey.ExternalAccounts.Bank.Create,
        (body: BankFormFields) => ExternalBankAccountService.create(body),
        { revalidateOnMount: false, revalidateOnFocus: false }
    )
}

export const useCountries = () => {
    return useSWR<ResponseSse, Error>(
        'Country',
        () => UserService.getCountriesSse({ locale: getLocale() }),
        // { revalidateOnMount: false, revalidateOnFocus: false }
    )
}
export const useListBankAccounts = () => {
    return useSWR<ResponseSse, Error>(
        SwrKey.ExternalAccounts.Bank.List,
        () => ExternalBankAccountService.list(),
        {revalidateOnMount: true, revalidateOnFocus: true}
    )
}