import { baseApiUrl } from 'assets/const'
import AuthService from 'services/auth.service'

export const api = async (url: string, intl?: RequestInit) => {
  const { headers = {}, ...rest } = intl ?? {}

  await AuthService.updateToken()
  const token = AuthService.getToken()

  const options: RequestInit = {
    headers: {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
      ...headers,
    },
    ...rest,
  }

  return fetch(baseApiUrl + url, options)
}
