import { SWRConfig } from 'swr'
import { toastError } from 'utils/toast'

type Props = {
  children: JSX.Element
}

const SwrConfigProvider = ({ children }: Props) => {
  return (
    <SWRConfig
      value={{
        onError: (error) => {
          toastError(error)
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}

export default SwrConfigProvider
