import { UseFormRegisterReturn } from 'react-hook-form'
import s from './CheckBox.module.scss'

interface Props extends React.HTMLProps<HTMLInputElement> {
  label: string
  register?: UseFormRegisterReturn<any>
}

const CheckBox = ({ label, register, disabled, ...props }: Props) => {
  return (
    <div className={s.wrapper}>
      <label>
        <span className={s.box}>
          <input type='checkbox' {...props} {...register} disabled={disabled} />
          <span className={s.mark}></span>
        </span>
        <span className={`${s.label} ${disabled ? s.labelDisabled : ''}`}>
          {label}
        </span>
      </label>
    </div>
  )
}

export default CheckBox
