import { useState } from 'react'
import { getAvatarInitials, getGravatarURL } from 'utils/avatar'
import s from './Avatar.module.scss'

interface Props {
  email?: string
  firstName?: string
  lastName?: string
  size?: number
  fontSize?: number
}

const Avatar = ({ email, firstName, lastName, fontSize = 14, size = 30 }: Props) => {
  const [isHasLoad, setIsHasLoad] = useState(false)
  const [isError, setIsError] = useState(false)
  const avatarUrl = email ? getGravatarURL(email) : undefined
  const initials = getAvatarInitials(firstName, lastName)

  return (
    <span className={s.avatarContainer}>
      {!isHasLoad && (
        <div
          className={s.textAvatar}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            fontSize: fontSize,
            lineHeight: 1.43,
            letterSpacing: -1
          }}
        >
          {initials}
        </div>
      )}
      {!isError && email && (
        <img
          src={avatarUrl}
          width={`${size}px`}
          height={`${size}px`}
          alt='Avatar'
          onLoad={() => setIsHasLoad(true)}
          onError={() => setIsError(true)}
          className={`${s.avatar} ${!isHasLoad ? s.hide : ''}`}
        />
      )}
    </span>
  )
}

export default Avatar
