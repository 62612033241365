import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import translationEN from 'locales/en/translation.json'
import translationRU from 'locales/ru/translation.json'

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: localStorage.getItem('cleverWalletLanguage') || 'en',
    fallbackLng: 'en',
    debug: true,
    resources: {
      en: { translation: translationEN },
      ru: { translation: translationRU },
    },
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
