import { Params } from 'assets/const'
import { useSearchParams } from 'react-router-dom'

export const useToken = () => {
  const [params] = useSearchParams()

  const token = params.get(Params.Token) || ''

  const tokenParam = `${Params.Token}=${token}`

  return {
    token,
    tokenParam,
  }
}
