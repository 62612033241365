import useSWR from 'swr'

import { SwrKey } from 'assets/const'
import { UserService } from 'services/user.service'

export const useUserProfile = () => {
  const user = useSWR(SwrKey.User, () => UserService.getProfile(), {
    errorRetryCount: 0,
    revalidateOnFocus: false,
  })

  return {
    ...user,
    isMangoPayer: Boolean(user.data?.businessSettings.paymentSettings.mango.payerId),
    isMangoOwner: Boolean(user.data?.businessSettings.paymentSettings.mango.ownerId),
  }
}
