import s from './PaperHeader.module.scss'

interface Props {
  text?: string
}

const PaperHeader = ({ text }: Props) => {
  return (
    <div className={s.paperHeader}>
      <h1>{text}</h1>
    </div>
  )
}

export default PaperHeader
