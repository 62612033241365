import { FC, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'

import TextField from 'components/TextField'
import s from './CustomDatePicker.module.scss'


interface CustomDatePickerProps {
  control: Control<any>
  name: string
  label: string
  required?: boolean
  errors?: any
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  control,
  name,
  label,
  required = false,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={`${errors?.[name] ? 'error' : ''}`}>
      <Controller
        control={control}
        name={name}
        rules={{ required: required ? 'This field is required' : false }}
        render={({ field: { onChange, value } }) => (
          <div className={s.calendarWrapper}>
            <ReactDatePicker
              selected={value ? dayjs(value).toDate() : null}
              onChange={(date: Date | null) => {
                const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                onChange(formattedDate);
              }}
              onCalendarClose={() => setIsOpen(false)}
              onCalendarOpen={() => setIsOpen(true)}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              customInput={
                <div>
                  <TextField
                    label={label}
                    value={value ? dayjs(value).format('YYYY-MM-DD') : ''}
                    onClick={() => setIsOpen(!isOpen)}
                    error={errors?.[name]?.message}
                    wrapperClassName={s.customTextField}
                    readOnly
                  />
                </div>
              }
              popperPlacement="bottom-start"
              popperClassName={s.customPopper}
            />
          </div>
        )}
      />
      {errors?.[name] && <span className="error-message">{errors[name].message}</span>}
    </div>
  );
};

export default CustomDatePicker;
