import CircularLoader from 'components/CircularLoader'
import s from './Preloader.module.scss'

const Preloader = () => {
  return (
    <div className={s.preloader}>
      <CircularLoader />
    </div>
  )
}

export default Preloader
