import BackButton from 'components/BackButton'
import { ReactNode } from 'react'
import { To } from 'react-router-dom'

import s from './BackSectionLayout.module.scss'

interface Props {
  children: ReactNode | ReactNode[]
  path?: To
  text: string
  isBackHide?: boolean
  onClick?: () => void
}

const BackSectionLayout = ({
  children,
  path,
  text,
  isBackHide,
  onClick,
}: Props) => {
  return (
    <div className={s.wrapper}>
      <div className={s.backSection}>
        {!isBackHide && (
          <BackButton path={path} text={text} onClick={onClick} />
        )}
      </div>
      {children}
    </div>
  )
}

export default BackSectionLayout
