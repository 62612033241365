import { Navigate, Outlet, useMatch, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { AppRoute } from 'assets/const'
import { useInvoice } from 'hooks/data/useInvoice'
import { usePath } from 'hooks/usePath'

import Typography from 'components/Typography'
import Modal from 'components/Modal'

import { ReactComponent as ExpiredIcon } from 'assets/svg/Expired.svg'


const PrivateInvoiceRoute = () => {
  const { data: invoice, error, isNewStatus, isProcessingStatus } = useInvoice()
  const navigate = useNavigate()
  const rootPath = usePath(AppRoute.Root)
  const isRoot = useMatch(AppRoute.Root)
  const errorPath = usePath(`/${AppRoute.Error}`)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isNewStatus && invoice && !isRoot && !isProcessingStatus) {
      setIsOpen(true)
    }
  }, [invoice?.status])

  if (error) {
    return <Navigate to={errorPath} />
  }

  return (
    <>
      <Outlet />
      <Modal
        title='Session Expired'
        message={
          <Typography variant='h5'>Please go back and start payment process again.</Typography>
        }
        icon={<ExpiredIcon style={{margin: '16px auto'}}/>}
        isOpen={isOpen}
        onClose={() => {
          navigate(rootPath)
        }}
        buttonText='Return'
      />
    </>
  )
}

export default PrivateInvoiceRoute
