import Skeleton, { SkeletonProps } from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

import s from './SkeletonLoader.module.scss'

interface Props extends SkeletonProps {
  size?: 'medium' | 'small'
}

const SkeletonLoader = ({ className, size, ...rest }: Props) => {
  return (
    <Skeleton
      {...rest}
      className={`${s.skeleton} ${size ? s[size] : ''} ${className ?? ''}`}
    />
  )
}
export default SkeletonLoader
