import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from 'components/Button'
import CustomSelect from 'components/CustomSelect'
import TextField from 'components/TextField'
import CheckBox from 'components/CheckBox/CheckBox'
import AppModal from 'components/AppModal'
import Preloader from 'components/Preloader'

import { UserProfile } from 'types/user-profile'
import { companyTypesOptions, profileTypesOptions } from 'assets/const'
import { MangoService } from 'services/mango.service'
import { toastError } from 'utils/toast'
import { useSseStore } from 'store/useSseStore'

import s from './CreateMangoProfileModal.module.scss'


interface Props {
  onClose: () => void
  userData: UserProfile
}

type FormFields = {
  firstName: string
  lastName: string
  email: string
  profileType: string
  companyName: string
  companyType: string
  agreement: boolean
}

const CreateMangoProfileModal = ({
  userData,
  onClose,
}: Props) => {
  const { t } = useTranslation()
  const { setLoading, loading } = useSseStore()

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
  } = useForm<FormFields>({
    values: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      profileType: profileTypesOptions[0].value,
      companyName: '',
      companyType: '',
      agreement: false,
    },
    mode: 'onChange',
  })

  const [profileType] = watch(['profileType'])

  const onSubmit: SubmitHandler<FormFields> = (formData) => {
    if (profileType === 'individual') {
      MangoService.createMangoNaturalProfile()
        .then(() => (setLoading(true)))
        .catch((e) => toastError(e))
    } else {
      MangoService.createMangoLegalProfile({
        personType: formData.companyType,
        companyName: formData.companyName,
        isTermsAndConditionsAccepted: formData.agreement,
      })
        .then(() => setLoading(true))
        .catch((e) => toastError(e))
    }
  }

  return (
    <AppModal title={t('Forms.CreateMangoAccount.Title')} onClose={onClose}>
      {loading && <Preloader />}
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          register={register('firstName')}
          label={`${t('Fields.FirstName')}*`}
          error={errors.firstName?.message}
          disabled
        />
        <TextField
          register={register('lastName')}
          label={`${t('Fields.LastName')}*`}
          disabled
        />
        <TextField
          register={register('email')}
          label={`${t('Fields.Email')}*`}
          type='email'
          disabled
        />
        <CustomSelect
          options={profileTypesOptions}
          name='profileType'
          control={control}
          label={`${t('Fields.ProfileType')}*`}
        />
        {profileType === 'company' && (
          <>
            <TextField
              register={register('companyName', {
                required: t('ValidationMessages.FieldRequired'),
              })}
              label={`${t('Fields.CompanyName')}*`}
              type='text'
              autoComplete='off'
              error={errors.companyName?.message}
            />
            <CustomSelect
              options={companyTypesOptions}
              name='companyType'
              control={control}
              label={`${t('Fields.CompanyType')}*`}
            />
          </>
        )}
        <CheckBox
          label={`${t('Forms.CreateMangoAccount.AcceptMangoTerms')}`}
          register={register('agreement', { required: true })}
          required
        />

        <div className={s.buttonsContainer}>
          <Button variant='outlined' fullWidth onClick={onClose}>
            {t('Buttons.Cancel')}
          </Button>
          <Button disabled={!isValid} type='submit' fullWidth>
            {t('Buttons.Create')}
          </Button>
        </div>
      </form>
    </AppModal>
  )
}

export default CreateMangoProfileModal
