import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/Button'
import LinearProgressBar from 'components/LinearProgressBar'
import Paper from 'components/Paper'
import Preloader from 'components/Preloader'
import Typography from 'components/Typography'
import BackSectionLayout from 'layouts/BackSectionLayout'
import WalletsSelect from './WalletsSelect'

import {
  AppRoute,
  PROGRESS_BAR_VISIBLE,
  ProgressBarTitle,
} from 'assets/const'
import { useInvoice } from 'hooks/data/useInvoice'
import { usePath } from 'hooks/usePath'
import { FinanceService } from 'services/finance.service'
import { useWalletsForInvoice } from 'hooks/data/useWallets'
import { useCurrencyInfo } from 'hooks/data/useCurrency'

import s from './CWPayment.module.scss'


export interface WalletOption {
  name: string
  balance: string
  id: string
}

const CWPayment = () => {
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const { search } = useLocation()
  const navigate = useNavigate()
  const rootPath = usePath(AppRoute.Root)

  const {
    data: invoiceData,
    isLoading,
    mutate: revalidateInvoice,
    isNewStatus,
  } = useInvoice()

  const { data: wallets, isWalletsLoading } = useWalletsForInvoice(
    invoiceData ? { currency: invoiceData.currency, amount: invoiceData.amount } : null
  );
  const { data: currency, isLoading: isCurrencyLoading } = useCurrencyInfo(wallets ? wallets[0].currency : null);

  const [options, setOptions] = useState<WalletOption[] | null>(null)
  const [selectedValue, setSelectedValue] = useState<WalletOption | null>(null);

  const invoiceLife = invoiceData
  ? invoiceData?.expired - invoiceData?.created
  : 0

  useEffect(() => {
    if (!isWalletsLoading && wallets) {
      setOptions(wallets?.map(wallet => ({ name: wallet.name, balance: wallet.balance, id: wallet.id })))
    }
  }, [wallets]);

  useEffect(() => {
    if (isExpired) {
      revalidateInvoice()
    }
  }, [isExpired])

  const handleClick = async() => {
    if (invoiceData  && wallets) {
      await FinanceService.payInvoice({
        invoiceId: invoiceData?.id,
        walletId: selectedValue?.id || wallets[0].id,
      })
      navigate(`/${AppRoute.Success}/${search}`)
    }
  }

  if (isLoading || isWalletsLoading || isCurrencyLoading) {
    return <Preloader />
  }

  return (
    <BackSectionLayout text='Back to payment information' path={rootPath}>
      <Paper className={s.container} style={{maxWidth: '564px', alignSelf: 'center'}}>
        {isNewStatus && (
          <>
            {PROGRESS_BAR_VISIBLE && (
              <LinearProgressBar
                awaitingTitle={ProgressBarTitle.AwaitingPayment}
                expiredTitle={ProgressBarTitle.ExpiredPayment}
                timeout={invoiceLife}
                created={invoiceData?.created}
                onExpired={setIsExpired}
              />
            )}
          </>
        )}
        <div>
          <Typography variant='h4'>Select wallet</Typography>
          <WalletsSelect options={options} currency={currency} selectedValue={selectedValue} setSelectedValue={setSelectedValue}/>
        </div>
        <Button onClick={handleClick}>Pay now</Button>
      </Paper>
    </BackSectionLayout>
  )
}

export default CWPayment
