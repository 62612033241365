import { useCallback, useState } from 'react'
import { PageHeading } from '../../../assets/const'
import Button from '../../../components/Button'
import Paper from '../../../components/Paper'
import PaperHeader from '../../../components/PaperHeader'
import ConfirmPopup from 'components/ConfirmPopup'
import BankAccountPopup from './BankAccountPopup'

import { toast } from 'react-toastify'
import MobileBankAccount from './MobileBankAccount'
import {
  useCountries,
  useListBankAccounts,
} from '../../../hooks/data/externalAccounts/useBankAccountsApi'
import { ExternalBankAccountService } from '../../../services/external-bank-account.service'
import { useSseStore } from '../../../store/useSseStore'
import { BankAccount, BankFormFields } from '../../../types/external-account'
import { useMatchMedia } from 'hooks/useMatchMedia'

import { ReactComponent as EditIcon } from 'assets/svg/EditIcon.svg'
import { ReactComponent as DeleteIcon } from 'assets/svg/DeleteIcon.svg'
import { ReactComponent as EmptyListIcon } from 'assets/svg/EmptyList.svg'

import s from '../ExternalAccounts.module.scss'


const BankAccounts = () => {
  const [isAddEdit, setAddEdit] = useState<boolean>(false)
  const [isRemove, setRemove] = useState<boolean>(false)
  const [currentAccount, setCurrentAccount] = useState<
    BankAccount | BankFormFields | null
  >(null)
  const { isTablet } = useMatchMedia()

  useListBankAccounts()
  useCountries()
  const { bankAccounts } = useSseStore()

  const addNewAccount = useCallback(() => {
    setAddEdit(true)
  }, [])

  const onEdit = useCallback((account: BankAccount) => {
    setCurrentAccount(account)
    setAddEdit(true)
  }, [])

  const onDelete = useCallback((account: BankAccount) => {
    setCurrentAccount(account)
    setRemove(true)
  }, [])

  const deleteAcc = () => {
    const id = currentAccount?.id
    if (!id) return
    ExternalBankAccountService.delete(id).then(() => {
      toast.success(`External bank account ${id} successfully deleted`)
      onClose()
    })
  }
  const onClose = () => {
    setAddEdit(false)
    setRemove(false)
    setCurrentAccount(null)
  }

  return (
    <>
      {isTablet ?
        <>
        <Paper textAlign='center'>
          <PaperHeader text={PageHeading.ExternalAccounts.Bank} />

            <div className={s.tableWrapper}>
              <div className={`${s.Rtable} ${s.RtableCols} ${s.RtableCollapse}`}>
                <div className={`${s.RtableRow} ${s.RtableRowHead}`}>
                  <div className={`${s.RtableCell} ${s.otherCell} ${s.idCell} ${s.leftAlignCell}`}>ID</div>
                  <div className={`${s.RtableCell} ${s.otherCell}`}>Account number</div>
                  <div className={`${s.RtableCell} ${s.otherCell}`}>Account holder name</div>
                  <div className={`${s.RtableCell} ${s.otherCell}`}>
                    Routing Number
                  </div>
                  <div className={`${s.RtableCell} ${s.otherCell}`}>Country</div>
                  <div className={`${s.RtableCell} ${s.idCell}`}>Currency</div>
                  <div className={`${s.RtableCell} ${s.idCell}`}></div>
                </div>

                {!bankAccounts?.length ? (
                  <div className={s.noAccounts}>
                    <EmptyListIcon/>
                    <span>The list is empty</span>
                  </div>
                ) : (
                  bankAccounts.map((account) => {
                    return (
                      <div className={s.RtableRow} key={account.id}>
                        <div className={`${s.RtableCell} ${s.otherCell} ${s.RtableCellHiddenText} ${s.idCell} ${s.leftAlignCell}`}>
                          <span>{account.id}</span>
                        </div>
                        <div className={`${s.RtableCell} ${s.otherCell}`}>
                          {account.accountNumber}
                        </div>
                        <div className={`${s.RtableCell} ${s.otherCell}`}>
                          {account.accountHolderName}
                        </div>
                        <div className={`${s.RtableCell} ${s.otherCell}`}>
                          {account.routingNumber}
                        </div>
                        <div className={`${s.RtableCell} ${s.otherCell}`}>
                          {account.country}
                        </div>
                        <div className={`${s.RtableCell} ${s.idCell}`}>
                          {account.currency}
                        </div>
                        <div className={`${s.RtableCell} ${s.idCell}`}>
                          <EditIcon
                            className={s.icon}
                            onClick={() => onEdit(account)}
                          />
                          <DeleteIcon
                            className={s.icon}
                            onClick={() => onDelete(account)}
                          />
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
            </div>
        </Paper>
      </>
      :
      bankAccounts.map((account) =>
        <MobileBankAccount
          key={account.id}
          account={account}
          onEdit={() => onEdit(account)}
          onDelete={() => onDelete(account)}
        />
      )
    }
    <div className={s.button}>
      <Button onClick={addNewAccount} type='submit' fullWidth>
        Add Bank account
      </Button>
    </div>

      <BankAccountPopup
        data={currentAccount}
        isOpen={isAddEdit}
        onClose={onClose}
        title={currentAccount ? 'Edit Bank account' : 'Add Bank account'}
      />
      <ConfirmPopup
        isOpen={isRemove}
        title={`Are you sure want remove account ${currentAccount?.id}?`}
        action={deleteAcc}
        onClose={onClose}
      />
      {/* <Popup isOpen={isRemove} onClose={onClose}>
        <div className={s.deletePopup}>
          <span
            className={s.text}
          >{`Are you sure want remove account ${currentAccount?.id}?`}</span>
          <div className={s.buttonsRemoveBlock}>
            <Button onClick={deleteAcc} type='button' fullWidth size='medium'>
              Yes
            </Button>
            <Button variant='outlined' fullWidth onClick={onClose}>
              No
            </Button>
          </div>
        </div>
      </Popup> */}
  </>
  )
}

export default BankAccounts
