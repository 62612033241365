import { FC } from 'react'
import UserInfo from 'components/UserInfo'
import { useMatchMedia } from 'hooks/useMatchMedia'

import { ReactComponent as LogoTitle } from 'assets/svg/CWLogoTitle.svg'
import { ReactComponent as LogoIcon } from 'assets/svg/CWLogoIcon.svg'

import s from './Header.module.scss'


interface Props {
  withLogo: boolean
}

const Header: FC<Props> = ({withLogo}) => {
  const { isLaptop, isDesktop } = useMatchMedia()

  return (
    <header className={s.headerWrapper}>
      <div className={s.headerContent}>
        {(withLogo || !isDesktop) &&
          <div className={s.logoWrapper}>
            {isLaptop && <LogoTitle color='var(--color-primary-text)'/>}
            <LogoIcon color='var(--color-primary)'/>
          </div>
        }
        <div className={s.user}>
          <UserInfo />
        </div>
      </div>
    </header>
  )
}

export default Header
