import useSWR from 'swr/immutable'
import {SwrKey} from 'assets/const';
import {MangoDictionary} from 'types/mango';
import {MangoService} from 'services/mango.service';
import { ResponseSse } from 'types/response';
import { MangoExternalBankAccountsService } from 'services/mango-external-bank-accounts.service';
import { mutate } from 'swr';
import {useSseStore} from '../../store/useSseStore';

export const useGetMangoDict = () => {
    return useSWR<MangoDictionary, Error>(
        SwrKey.Mango.GetDict,
        () => MangoService.getDict(),
        { revalidateOnMount: true, revalidateOnFocus: false }
    )
}

export const useCreateMangoCard = (payload:any) => {
    return useSWR<any, Error>(
        payload ? SwrKey.Mango.CreateMangoCard : null,
        () => MangoService.createMangoCard(payload),
        { revalidateOnMount: true, revalidateOnFocus: false }
    )
}

export const useMangoPaymentInfo = (invoiceId: string | undefined) => {
    return useSWR<any, Error>(
        SwrKey.Mango.MangoPaymentInfo,
        () => invoiceId ? MangoService.getMangoPaymentInfo(invoiceId) : null,
        { revalidateOnMount: true, revalidateOnFocus: false, refreshInterval: 30000 }
    )
}
export const useGetMangoCountriesSse = () => {
  return useSWR<ResponseSse, Error>(
        SwrKey.Mango.GetCountries,
        () => MangoService.getMangoCountriesSse(),
    )
}

export const useGetMangoNaturalProfileSse = () => {
  return useSWR<ResponseSse, Error>(
        SwrKey.Mango.CreateMangoLegalProfile,
        () => MangoService.getMangoNaturalProfileSse(),
    )
}

export const useGetMangoLegalProfileSse = () => {
  return useSWR<ResponseSse, Error>(
        SwrKey.Mango.CreateMangoLegalProfile,
        () => MangoService.getMangoLegalProfileSse(),
    )
}

//mango bank accounts
export const useGetMangoBankAccountsList = () => {
  const {setLoading} = useSseStore()
  return useSWR<ResponseSse, Error>(
      SwrKey.MangoExternalBankAccountsService.Get,
      () => (setLoading(true), MangoExternalBankAccountsService.getList()),
      {revalidateOnMount: true, revalidateOnFocus: true}
  )
}

export const useGetMangoBankAccountDetails = (id: string) => {
  return useSWR<ResponseSse, Error>(
      SwrKey.MangoExternalBankAccountsService.GetDetails,
      () => MangoExternalBankAccountsService.getDetails(id),
      {revalidateOnMount: true, revalidateOnFocus: true}
  )
}

//kyc
export const useCreateKYC = () => {
  return useSWR<any, Error>(
      SwrKey.Mango.MangoCreateKYC,
      () => MangoService.createKYC(),
{ revalidateOnMount: false, revalidateOnFocus: false}
  )
}

export const useMangoKycInfo = () => {
    return useSWR<any, Error>(
        SwrKey.Mango.MangoKycInfo,
        () => MangoService.getKycInfo().then(x => {
            mutate(SwrKey.User, (profile)=> {
                profile.businessSettings.paymentSettings.mango = {...profile.businessSettings.paymentSettings.mango, kycStatus: x.status}
                return {...profile}
            }, {
                revalidate: false})

            useSseStore.getState().setMangoKycInfo({
                mangoKycDocumentId: x.mangoDocumentId,
                mangoUserId: x.mangoUserId,
                status: x.status,
            })
            }
        ),
        { revalidateOnMount: false, revalidateOnFocus: false, refreshInterval: 30000}
    )
}