import { ComponentProps, ElementType } from 'react'

import CircularLoader from 'components/CircularLoader'
import s from './Button.module.scss'

type ButtonOwnProps<E extends ElementType> = {
  as?: E
  variant?: 'filled' | 'outlined'
  size?: 'large' | 'medium' | 'small'
  fullWidth?: boolean
  asDisabled?: boolean
  loading?: boolean
}

type ButtonProps<E extends ElementType> = ButtonOwnProps<E> &
  Omit<ComponentProps<E>, keyof ButtonOwnProps<E>>

const defaultElement = 'button'

const loaderSizes = {
  large: 44,
  medium: 32,
  small: 24,
}

const Button = <E extends ElementType = typeof defaultElement>({
  as,
  children,
  variant = 'filled',
  size = 'medium',
  className,
  fullWidth,
  asDisabled,
  loading,
  ...rest
}: ButtonProps<E>) => {
  const Component = as || defaultElement

  const loaderSize = loaderSizes[size]

  return (
    <Component
      className={`${s.button} ${s[variant]} ${s[size]} ${
        fullWidth ? s.fullWidth : ''
      } ${asDisabled ? s.disabled : ''} ${className ?? ''} ${
        loading ? s.loading : ''
      }`}
      {...rest}
    >
      {loading && (
        <CircularLoader
          strokeColor={
            variant === 'outlined'
              ? 'var(--color-primary)'
              : 'var(--color-white)'
          }
          size={loaderSize}
          className={`${s.buttonLoader}`}
        />
      )}
      {!loading && children}
    </Component>
  )
}

export default Button
