import { useEffect } from 'react'
import Preloader from 'components/Preloader'
import AppModal from 'components/AppModal'

import { useGetMangoBankAccountDetails } from 'hooks/data/useMangoApi'
import { useSseStore } from 'store/useSseStore'
import { formatCamelCaseToText } from 'utils/formatCamelCaseToText'

import s from './DetailsModal.module.scss'


interface Props {
  onClose: () => void
  id: string
}

const DetailsModal = ({ onClose, id }: Props) => {
  useGetMangoBankAccountDetails(id)
  const {mangoBankAccount, setMangoBankAccount} = useSseStore()

  useEffect(() => {
    mangoBankAccount?.bankAccountId !== id && setMangoBankAccount(undefined)
  }, [])

  if (!mangoBankAccount) return <Preloader />

  return (
    <AppModal title={'Bank account information'} onClose={onClose}>
      <div className={s.container}>
      {Object.entries(mangoBankAccount)
        .filter(([title]) => title !== 'address' && title !== 'isActive')
        .map(([title, value]) => (
          <div className={s.flexBox} key={title}>
            <p className={s.titleText}>{formatCamelCaseToText(title)}:</p>
            <p className={s.valueText}>{value}</p>
          </div>
        ))
      }
      {Object.entries(mangoBankAccount.address)
        .map(([title, value]) => (
          <div className={s.flexBox} key={title}>
            <p className={s.titleText}>{formatCamelCaseToText(title)}:</p>
            <p className={s.valueText}>{value}</p>
          </div>
        ))
      }
      </div>
    </AppModal>
  )
}

export default DetailsModal
