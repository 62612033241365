import s from './Paper.module.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent'
  borderRadius?: string
  padding?: string
}

const Paper = ({
  children,
  className,
  textAlign = 'left',
  borderRadius = 'var(--radius-primary)',
  // padding = '24px',
  style,
  ...rest
}: Props) => {
  return (
    <div
      className={`${s.paperWrapper} ${className}`}
      style={{ textAlign, borderRadius: borderRadius, ...style }}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Paper
