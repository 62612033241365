import { MessageType, SwrKey, baseApiUrl } from 'assets/const'
import { useEffect, useRef } from 'react'
import { toast } from 'react-toastify'
import AuthService from 'services/auth.service'
import { useSseStore } from 'store/useSseStore'
import { useSWRConfig } from 'swr'

import { uuid } from 'utils/uuid'
import {UserMangoStatus} from 'types/mango';
import { toastError } from 'utils/toast'

const EventSource = require('sse-events')

const SSE_TIMEOUT = 600000
const TOKEN_VALIDITY = 1500

type Props = {
  children: JSX.Element
}

const SseProvider = ({ children }: Props) => {
  const { mutate } = useSWRConfig()
  const {
    isPendingCancellation,
    setLoading,
    setModal,
    setCountries,
    setMangoCountries,
    setMangoNaturalProfile,
    setMangoLegalProfile,
    setStripeAccountLink,
    setConnectedAccount,
    setSseErrorMessages,
    setCryptoSettings,
    setStripeSettings,
    setBankAccounts,
    setMangoBankAccounts,
    setMangoBankAccount,
    setCardAccounts,
    setIsPendingCancellation,
    setMangoUserStatus,
    setCreateMangoCardResponse,
    setMangoKycInfo
  } = useSseStore()
  const eventSource = useRef<any | null>(null)

  useEffect(() => {
    if (eventSource.current) {
      return
    }
    const connect = async () => {
      const token = await AuthService.updateTokenSse(TOKEN_VALIDITY)

      eventSource.current = new EventSource({
        url: `${baseApiUrl}sse?sessionId=${uuid}`,
        options: {
          headers: {
            Authorization: 'Bearer ' + token,
          },
          withCredentials: false,
          connectionTimeout: SSE_TIMEOUT,
        },
        retryOnServerError: true,
        retryOnNetworkError: true,
      })

      eventSource.current.addEventListener('open', (e: any) => {
        console.log('open', e)
      })

      eventSource.current.addEventListener('state', (e: any) => {
        console.log('state', e)
      })

      eventSource.current.addEventListener('error', (e: any) => {
        eventSource.current.close()
        delete eventSource.current.options.headers.Authorization
        eventSource.current.retrying = true
        AuthService.updateTokenSse(TOKEN_VALIDITY)
          .then((res) => {
            eventSource.current.setHeaders({ Authorization: 'Bearer ' + res })
            eventSource.current.open()
          })
          .catch(() => null)
        eventSource.current.retrying = false
      })

      eventSource.current.addEventListener('message', (e: any) => {
        console.log('message:', e)
        const data = e.data
        if (!!data.payload?.error) {
          console.log('error!!!!!!', data)
          setLoading(false)
          toastError(data.payload?.error || data.payload?.message || 'Backend error')
          setSseErrorMessages({
            message: data.payload?.message,
            type: data.type,
          })
          if (data.type === MessageType.Invoice || isPendingCancellation) {
            mutate(SwrKey.Invoice).finally(() => {
              setIsPendingCancellation(false)
            })
          }
          return
        }
        switch (data.type) {
          case MessageType.Country:
            setCountries(data.payload?.countries)
            break
          case MessageType.MangoCountry:
            setMangoCountries(data.payload?.mangoCountries)
            break
          case MessageType.NaturalUser:
            setMangoNaturalProfile(data.payload)
            break
          case MessageType.LegalUser:
            setMangoLegalProfile(data.payload)
            break
          case MessageType.EditUserLegal:
            setMangoLegalProfile(data.payload)
            setModal(null)
            setLoading(false)
            mutate(SwrKey.User)
            break
          case MessageType.EditUserNatural:
            setMangoNaturalProfile(data.payload)
            setModal(null)
            setLoading(false)
            mutate(SwrKey.User)
            break
          //Mango external bank account
          case MessageType.MangoExternalBankAccountList:
            setMangoBankAccounts(data.payload)
            setLoading(false)
            break
          case MessageType.MangoExternalBankAccount:
            setMangoBankAccount(data.payload)
            break
          case MessageType.DeactivateBankAccount:
            toast.success(`External bank account successfully deleted`)
            setModal(null)
            mutate(SwrKey.MangoExternalBankAccountsService.Get)
            break
          case MessageType.CreateMangoExternalBankAccount:
            toast.info('External bank account successfully added')
            setModal(null)
            mutate(SwrKey.MangoExternalBankAccountsService.Get)
            break
          case MessageType.Account:
            setStripeAccountLink(data.payload?.link)
            setConnectedAccount(data.payload?.account)
            break
          case MessageType.Link:
            setStripeAccountLink(data.payload)
            break
          case MessageType.Operation:
            if (data.payload?.clientSecret) {
              setStripeSettings(data.payload)
            }
            if (data.payload?.address) {
              setCryptoSettings(data.payload)
            }
            break
          case MessageType.ExternalAccount.Bank:
            setBankAccounts(data.payload)
            break
          case MessageType.ExternalAccount.Card:
            toast.info('External card account successfully added or changed')
            setCardAccounts(data.payload)
            break
          case MessageType.Invoice:
            mutate(SwrKey.Invoice).finally(() => {
              setIsPendingCancellation(false)
            })
            break
          case MessageType.CreateMangoNaturalUser:
            if (data.payload.payerId) {
              setMangoUserStatus(UserMangoStatus.created)
              setModal(null)
              setLoading(false)
              mutate(SwrKey.User, (profile)=> {
                profile.businessSettings.paymentSettings.mango.payerId = data.payload.payerId
                return profile
              }, {
                revalidate: true})
              }
              break
            case MessageType.CreateMangoLegalUser:
              if (data.payload.payerId) {
                setModal(null)
                setLoading(false)
                mutate(SwrKey.User, (profile)=> {
                  return profile
                })
              }
              break
          case MessageType.CreateMangoCard:
            setCreateMangoCardResponse(data.payload)
            break
          case MessageType.ExecuteDirectMangoCardPayIn:
            const {executionDetails} = data.payload?.payment;
            let url = `${window.location.origin}/mango/payment/status?token=${data.payload?.invoiceId}`
            // with 3ds
            if (executionDetails?.secureModeRedirectUrl) {
              url = executionDetails?.secureModeRedirectUrl
            }

            window.location.replace(url)
            break
          case MessageType.KycDocument:
          case MessageType.SubmitDocument:
          case MessageType.UploadKycDocument:
            if (data.payload.status === 'NotSpecified') {
              //ниче не делаем, просто бек не умеет по другому)
              return;
            }

            mutate(SwrKey.User, (profile)=> {
              //специально объект, понятно что ререндер. но пока бек так шлет, статусы по загрузке, придется делать так
              profile.businessSettings.paymentSettings.mango = {...profile.businessSettings.paymentSettings.mango, kycStatus: data.payload.status}
              return {...profile}
            }, {
              revalidate: false})
            setMangoKycInfo(data.payload)
            break
        }
      })
      eventSource.current.addEventListener('close', (e: any) => {
        console.log('close', e)
      })

      eventSource.current.addEventListener('timeout', (e: any) => {
        console.log('timeout', e)
      })
      eventSource.current.open()
    }
    connect()
  }, [])

  return children
}

export default SseProvider
