import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from 'components/Button'
import LinearProgressBar from 'components/LinearProgressBar'
import Modal from 'components/Modal'
import Paper from 'components/Paper'
import Preloader from 'components/Preloader'
import Typography from 'components/Typography'
import BackSectionLayout from 'layouts/BackSectionLayout'

import {
  AppRoute,
  CryptoLink,
  MessageType,
  PROGRESS_BAR_VISIBLE,
  PaymentSystemCode,
  ProgressBarTitle,
} from 'assets/const'
import { useInvoice } from 'hooks/data/useInvoice'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'
import { usePath } from 'hooks/usePath'
import { FinanceService } from 'services/finance.service'
import { useSseStore } from 'store/useSseStore'
import { formatNumber } from 'utils/intl'
import s from './CryptoPayment.module.scss'


const CryptoPayment = () => {
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const navigate = useNavigate()
  const { paymentId } = useParams()
  const { cryptoSettings, sseErrorMessages, clearErrorMessage } = useSseStore()
  const { copyToClipboard, copied } = useCopyToClipboard()
  const {
    data: invoiceData,
    isLoading,
    mutate: revalidateInvoice,
    isNewStatus,
  } = useInvoice()

  const [isLoadingSse, setIsLoadingSse] = useState(true)
  const rootPath = usePath(AppRoute.Root)

  const errorMessage = sseErrorMessages
    .find((msg) => msg.type === MessageType.Operation)
    ?.message.split(';')[0]

  const invoiceLife = invoiceData
    ? invoiceData?.expired - invoiceData?.created
    : 0
  const paymentCode = String(paymentId) as keyof typeof PaymentSystemCode
  // const paymentImage = invoiceData?.paymentSystems.find(
  //   (ps) => ps.code === paymentCode
  // )?.icon
  const link = CryptoLink[paymentCode]
    ? `${CryptoLink[paymentCode]}:${cryptoSettings?.address}?amount=${invoiceData?.amount}`
    : ''

  const handleCopyClick = (value: string) => {
    copyToClipboard(value)
  }

  useEffect(() => {
    if (isExpired) {
      revalidateInvoice()
    }
  }, [isExpired])

  useEffect(() => {
    if (!invoiceData || !isLoadingSse || !paymentCode) {
      return
    }
    FinanceService.getPaymentSettingsCryptoSse({
      invoiceId: invoiceData.id,
      paymentSystemCode: paymentCode,
    })
    setIsLoadingSse(false)
  }, [invoiceData])

  if (errorMessage) {
    return (
      <Modal
        message={errorMessage}
        isOpen={!!errorMessage}
        onClose={() => {
          navigate(rootPath)
          clearErrorMessage(MessageType.Operation)
        }}
      />
    )
  }

  if (isLoading || !cryptoSettings) {
    return <Preloader />
  }

  return (
    <BackSectionLayout text='Back to payment information' path={rootPath}>
      <Paper textAlign='center' style={{maxWidth: '564px', alignSelf: 'center'}}>
        {isNewStatus && (
          <>
            {PROGRESS_BAR_VISIBLE && (
              <LinearProgressBar
                awaitingTitle={ProgressBarTitle.AwaitingPayment}
                expiredTitle={ProgressBarTitle.ExpiredPayment}
                timeout={invoiceLife}
                created={invoiceData?.created}
                onExpired={setIsExpired}
              />
            )}
            <div className={s.flexBox}>
              <div className={s.qrCode}>
                <QRCodeSVG
                  value={cryptoSettings.address}
                  size={250}
                  level='M'
                  // imageSettings={{
                  //   src: paymentImage || Logo[paymentCode],
                  //   height: 50,
                  //   width: 50,
                  //   excavate: true,
                  // }}
                />
              </div>

              <ul className={s.list}>
                <li className={s.listItem}>
                  <Typography variant='h5'>
                    Amount: &ensp; {invoiceData ? `   ${formatNumber(invoiceData?.amount, invoiceData?.currency)}` : ''}
                  </Typography>
                </li>
                <li className={s.listItem}>
                  <Typography variant='h5'>
                    Pay with: &ensp; {paymentCode}
                  </Typography>
                </li>
              </ul>
            </div>

            <div className={s.buttonsContainer}>
              <Button
                type='button'
                variant='outlined'
                onClick={() => handleCopyClick(cryptoSettings.address)}
                className={s.button}
              >
                Copy Address
              </Button>
              <Button
                type='button'
                variant='outlined'
                className={s.button}
                onClick={() => handleCopyClick(link)}
              >
                Copy payment link
              </Button>
            </div>
          </>
        )}
      </Paper>
    </BackSectionLayout>
  )
}

export default CryptoPayment
