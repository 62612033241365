import { useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import Button from 'components/Button'
import TextField from 'components/TextField'
import Preloader from 'components/Preloader'
import AppModal from 'components/AppModal'
import CustomFormInputSelect from 'components/CustomFormInputSelect'
import CustomSelect from 'components/CustomSelect'

import { useSseStore } from 'store/useSseStore'
import { useGetMangoCountriesSse } from 'hooks/data/useMangoApi'
import { MangoExternalBankAccountsService } from 'services/mango-external-bank-accounts.service'
import { MangoBankAccountsTypes } from 'types/mango'
import { requiredFieldText } from 'assets/const'
import { toastError } from 'utils/toast'
import { ApiError } from 'types/configuration'

import s from './AddModal.module.scss'


interface Props {
  onClose: () => void
}

export type SelectOption = {
  label: string
  value: string
}

type FormFields = {
  tag: string
  ownerName: string
  country: string
  city: string
  region: string
  postalCode: string
  addressLineFirst: string
  addressLineSecond: string | undefined
  type: string
  iban: string
  bic: string
  accountNumber: string
  aba: string
  depositAccountType: string
  institutionNumber: string
  branchCode: string
  bankName: string
  sortCode: string
  countryCode: string
}

const depositAccountTypeOptions = [
  { label: 'Checking', value: 'CHECKING' },
  { label: 'Savings', value: 'SAVINGS' },
]

const typeOptions: { label: MangoBankAccountsTypes; value: MangoBankAccountsTypes }[] = [
  { label: 'IBAN', value: 'IBAN' },
  { label: 'US', value: 'US' },
  { label: 'CA', value: 'CA' },
  { label: 'GB', value: 'GB' },
  { label: 'OTHER', value: 'OTHER' },
]

const BankAccountForm = ({ onClose }: Props) => {
  useGetMangoCountriesSse()
  const { mangoCountries, setLoading, loading } = useSseStore()

  const countriesOptions = useMemo(() => {
    if (!mangoCountries) return null
    return mangoCountries?.map((country) => ({
      value: country.code,
      label: country.name,
    }))
  }, [mangoCountries])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    watch,
  } = useForm<FormFields>({
    values: {
      tag: '',
      ownerName: '',
      country: '',
      city: '',
      region: '',
      postalCode: '',
      addressLineFirst: '',
      addressLineSecond: '',
      type: '',
      //iban
      iban: '',
      bic: '',
      //us
      accountNumber: '',
      aba: '',
      depositAccountType: '',
      //ca
      institutionNumber: '',
      branchCode: '',
      bankName: '',
      //gb
      sortCode: '',
      //other
      countryCode: ''
    },
    mode: 'onChange',
  })

  const type = watch('type')

  const onSubmit: SubmitHandler<FormFields> = ({
    addressLineFirst, addressLineSecond, city, region, postalCode, country, tag, iban, bic, accountNumber, aba,
    depositAccountType, institutionNumber, branchCode, bankName, sortCode, countryCode
  }) => {
    const generalRequestData = {
      address : {
        addressLine1: addressLineFirst,
        addressLine2: addressLineSecond,
        city,
        region,
        postalCode,
        country
      },
      tag
    }

    const successfulAddition = () => {
      setLoading(true)
    }

    const errorAddition = (e: ApiError) => {
      setLoading(false)
      toastError(e)
    }

    switch (type) {
      case 'IBAN':
        MangoExternalBankAccountsService.createIban({...generalRequestData, tag, iban, bic})
          .then(successfulAddition).catch(errorAddition)
        break
      case 'US':
        MangoExternalBankAccountsService.createUs({...generalRequestData, accountNumber, aba, depositAccountType})
          .then(successfulAddition).catch(errorAddition)
        break
      case 'CA':
        MangoExternalBankAccountsService.createCa({...generalRequestData, accountNumber, institutionNumber, bankName, branchCode})
          .then(successfulAddition).catch(errorAddition)
        break
      case 'GB':
        MangoExternalBankAccountsService.createGb({...generalRequestData, accountNumber, sortCode})
          .then(successfulAddition).catch(errorAddition)
        break
      case 'OTHER':
        MangoExternalBankAccountsService.createOther({...generalRequestData, accountNumber, countryCode, bic})
          .then(successfulAddition).catch(errorAddition)
        break
      }
  }

  if (!countriesOptions) return <Preloader />

  return (
    <AppModal title={'Add Bank account'} onClose={onClose}>
      {loading && <Preloader />}
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          register={register('tag', {
            required: requiredFieldText,
          })}
          label='Tag*'
          error={errors.tag?.message}
        />
        <TextField
          register={register('ownerName', {
            required: requiredFieldText,
          })}
          label='Owner Name*'
          error={errors.ownerName?.message}
        />
        <CustomFormInputSelect
          required
          options={countriesOptions || []}
          name='country'
          control={control}
          label='Country'
        />

        <div className={s.flexContainer}>
          <TextField
            register={register('city', {
              required: requiredFieldText,
            })}
            label='City*'
            error={errors.city?.message}
          />
          <TextField
            register={register('region')}
            label='Region'
          />
        </div>
        <TextField
          register={register('postalCode', {
            required: requiredFieldText,
          })}
          label='Postal code*'
          error={errors.postalCode?.message}
        />
        <TextField
          register={register('addressLineFirst', {
            required: requiredFieldText,
          })}
          label='Adress line 1*'
          error={errors.addressLineFirst?.message}
        />
        <TextField
          register={register('addressLineSecond')}
          label='Adress line 2'
        />
        <CustomSelect
          label={'Type*'}
          options={typeOptions}
          name='type'
          control={control}
          required
        />

        {type === 'IBAN' &&
          <>
            <TextField
              register={register('iban', {
                required: requiredFieldText,
              })}
              label='IBAN*'
              error={errors.iban?.message}
            />
            <TextField
              register={register('bic', {
              })}
              label='BIC*'
              error={errors.bic?.message}
            />
          </>
        }

        {type === 'US' &&
          <>
            <TextField
              register={register('accountNumber', {
                required: requiredFieldText,
                pattern: {
                  value: /^\d{4,17}$/,
                  message: 'Must be 4-17 digits',
                },
              })}
              label='Account Number*'
              error={errors.accountNumber?.message}
            />
            <TextField
              register={register('aba', {
                required: requiredFieldText,
                pattern: {
                  value: /^\d{9}$/,
                  message: 'Must be exactly 9 digits',
                },
              })}
              label='ABA*'
              error={errors.aba?.message}
            />
            <CustomSelect
              label={'Deposit Account Type'}
              options={depositAccountTypeOptions}
              name='depositAccountType'
              control={control}
              required
            />
          </>
        }

        {type === 'CA' &&
          <>
            <TextField
              register={register('accountNumber', {
                required: requiredFieldText,
                pattern: {
                  value: /^\d{7,12}$/,
                  message: 'Must be 7-12 digits',
                },
              })}
              label='Account Number*'
              error={errors.accountNumber?.message}
            />
            <div className={s.flexContainer}>
              <TextField
                register={register('institutionNumber', {
                  required: requiredFieldText,
                  pattern: {
                    value: /^\d{3}$/,
                    message: 'Must be exactly 3 digits',
                  },
                })}
                label='Institution Number*'
                error={errors.institutionNumber?.message}
              />
              <TextField
                register={register('branchCode', {
                  required: requiredFieldText,
                  pattern: {
                    value: /^\d{5}$/,
                    message: 'Must be exactly 5 digits',
                  },
                })}
                label='Branch Code*'
                error={errors.branchCode?.message}
              />
            </div>
            <TextField
              register={register('bankName', {
                required: requiredFieldText,
              })}
              label='Bank Name*'
              error={errors.bankName?.message}
            />
          </>
        }

        {type === 'GB' &&
          <>
            <TextField
              register={register('accountNumber', {
                required: requiredFieldText,
                pattern: {
                  value: /^\d{8}$/,
                  message: 'Must be exactly 8 digits',
                },
              })}
              label='Account Number*'
              error={errors.accountNumber?.message}
            />
            <TextField
              register={register('sortCode', {
                required: requiredFieldText,
                pattern: {
                  value: /^\d{6}$/,
                  message: 'Must be exactly 6 digits',
                },
              })}
              label='Sort Code*'
              error={errors.sortCode?.message}
            />
          </>
        }

        {type === 'OTHER' &&
          <>
            <TextField
              register={register('accountNumber', {
                required: requiredFieldText,
              })}
              label='Account Number*'
              error={errors.accountNumber?.message}
            />
            <div className={s.flexContainer}>
              <TextField
                register={register('bic', {
                })}
                label='BIC*'
                error={errors.bic?.message}
              />
              <CustomFormInputSelect
                required
                options={countriesOptions || []}
                name='countryCode'
                control={control}
                label='Bank country'
              />
            </div>
          </>
        }

        <div className={s.buttonsContainer}>
          <Button variant='outlined' fullWidth onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={ !isValid }
            type='submit'
            fullWidth
          >
            Save
          </Button>
        </div>
      </form>
    </AppModal>
  )
}

export default BankAccountForm
