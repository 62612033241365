import { Navigate } from 'react-router-dom'

import Paper from 'components/Paper'
import PaperHeader from 'components/PaperHeader'
import Preloader from 'components/Preloader'
import Button from 'components/Button'
import MangoProfileInfo from 'components/MangoProfileInfo'
import CreateMangoProfileModal from 'components/CreateMangoProfileModal'
import EditMangoIndividualProfileModal from 'components/EditMangoIndividualProfileModal'
import EditMangoCompanyProfileModal from 'components/EditMangoCompanyProfileModal'
import Typography from 'components/Typography'

import { AppRoute, PageHeading } from 'assets/const'
import { useUserProfile } from 'hooks/data/useUserProfile'
import { usePath } from 'hooks/usePath'
import { useSseStore } from 'store/useSseStore'

import s from './Settings.module.scss'


const MangoProfile = () => {
  const { data: userData, isValidating, isMangoOwner, isMangoPayer } = useUserProfile()
  const errorPath = usePath(`/${AppRoute.Error}`)

  const { modal, setModal} = useSseStore()
  const isAccountExist = isMangoPayer || isMangoOwner
  const IsIndividual = userData?.businessSettings.paymentSettings.mango.type === 'NATURAL'

  if (!userData && !isValidating) {
    return <Navigate to={errorPath} />
  }

  if (isValidating || !userData) {
    return (
      <div style={{ position: 'relative', minHeight: '400px' }}>
        <Preloader />
      </div>
    )
  }

  return (
    <Paper textAlign='center'>
      <div className={s.headerContainer}>
        <PaperHeader text={PageHeading.MangoProfile} />
        {isAccountExist &&
          <Typography variant='h4' className={s.secondaryHeader}>
            {IsIndividual ? '(Individual)' : '(Company)'}
          </Typography>
        }
      </div>
      {isAccountExist && <MangoProfileInfo userData={userData}/>}

      <div className={s.buttonsContainer}>
        <Button onClick={() => setModal(isAccountExist ? 'EDIT' : 'CREATE') } type='button' fullWidth>
          {isAccountExist ? 'Edit Mango Account' : 'Create Account'}
        </Button>
      </div>

      {modal === 'CREATE' && <CreateMangoProfileModal userData={userData} onClose={() => setModal(null)}/> }
      {modal === 'EDIT' && IsIndividual && <EditMangoIndividualProfileModal userData={userData} onClose={() => setModal(null)}/> }
      {modal === 'EDIT' && !IsIndividual && <EditMangoCompanyProfileModal userData={userData} onClose={() => setModal(null)}/> }
    </Paper>
  )
}

export default MangoProfile
