import { api } from 'api/api'
import { Invoice } from 'types/invoice'
import { Currency, PaymentPayReq, PaymentSettingsReq, PaymentWalletsReq, Wallet } from 'types/payment-settings'
import { ResponseSse } from 'types/response'
import { toastError } from 'utils/toast'
import { uuid } from 'utils/uuid'

export const FinanceService = {
  async getInvoice(token: string): Promise<Invoice> {
    return api(`invoice/${token}`).then(async (r) => {
      if (r.ok) {
        return r.json()
      } else {
        return r.json().then((err) => Promise.reject(err))
      }
    })
  },

  async cancelInvoice(invoiceId: string): Promise<string> {
    return api(`invoice/cancel`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ invoiceId, sessionId: uuid }),
    })
      .then((r) => r.json())
      .catch((err) => {
        toastError(err)
      })
  },

  async getPaymentSettingsCryptoSse(
    settings: PaymentSettingsReq
  ): Promise<ResponseSse> {
    return api(`invoice/payment-settings/crypto`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...settings, sessionId: uuid }),
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  async getPaymentSettingsStripeSse(
    settings: PaymentSettingsReq
  ): Promise<ResponseSse> {
    return api(`invoice/payment-settings/stripe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...settings, sessionId: uuid }),
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json()
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  async getWalletsForInvoice(
    {currency, amount}: PaymentWalletsReq
  ): Promise<Wallet[]> {
    return api(`invoice/wallets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ currency, amount}),
    })
      .then(async (r) => {
        if (r.ok) {
          const data = await r.json();
          return data.wallets;
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  async payInvoice(
    body: PaymentPayReq
  ): Promise<void> {
    return api(`invoice/pay`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({...body, sessionId: uuid}),
    })
      .then(async (r) => {
        if (r.ok) {
          return r.json();
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },

  async getCurrencyInfo(
    currencyCode: string
  ): Promise<Currency> {
    return api(`currency/${currencyCode}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (r) => {
        if (r.ok) {
          const data = await r.json();
          return data.currency;
        } else {
          return r.json().then((err) => Promise.reject(err))
        }
      })
      .catch((err) => {
        toastError(err)
      })
  },
}
