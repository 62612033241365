import { QUERIES, SCREENS } from 'assets/const'
import { useLayoutEffect, useState } from 'react'

type Matches = {
  isMobile: boolean
  isTablet: boolean
  isLaptop: boolean
  isDesktop: boolean
  isBigScreen: boolean
}

export const useMatchMedia = (): Matches => {
  const mediaQueryLists = QUERIES.map((query) => matchMedia(query))

  const getValues = () => mediaQueryLists.map((list) => list.matches)

  const [values, setValues] = useState(getValues)

  useLayoutEffect(() => {
    const handler = () => setValues(getValues)

    mediaQueryLists.forEach((list) => list.addEventListener('change', handler))

    return () =>
      mediaQueryLists.forEach((list) =>
        list.removeEventListener('change', handler)
      )
  }, [])

  const screenQuery = SCREENS.reduce(
    (acc, screen, index) => ({
      ...acc,
      [screen]: values[index],
    }),
    {} as Matches
  )

  return screenQuery
}
