import { useLayoutEffect, useRef, useState } from 'react'
import { getTimeStump } from 'utils/time'

export const useNow = (
  updateInterval: number,
  enabled: boolean,
  cb: (now: number) => void
) => {
  const cbRef = useRef(cb)
  cbRef.current = cb
  const [now, setNow] = useState(getTimeStump())

  useLayoutEffect(() => {
    if (!enabled) {
      return
    }

    setNow(getTimeStump())
    cbRef.current?.(getTimeStump())
    const interval = setInterval(() => {
      setNow(getTimeStump())
      cbRef.current?.(getTimeStump())
    }, updateInterval)

    return () => {
      clearInterval(interval)
    }
  }, [updateInterval, enabled])
  return now
}
