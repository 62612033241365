import SkeletonLoader from 'components/SkeletonLoader'
import StatusLabel from 'components/StatusLabel'
import Typography from '../Typography'

import { useInvoice } from 'hooks/data/useInvoice'
import { formatNumber } from 'utils/intl'

import s from './InvoiceInfo.module.scss'


const InvoiceInfo = () => {
  const { data, isLoading, isValidating } = useInvoice()

  return (
    <>
      {isLoading || isValidating ? (
        <SkeletonLoader
          count={5}
          height={60}
          containerClassName={s.skeletonRow}
        />
      ) : (
        <div>
          <div className={s.row}>
            <Typography variant='h5'>Amount:</Typography>
            <Typography>
              {data ? formatNumber(data?.amount, data?.currency) : ''}
            </Typography>
          </div>

          <div className={s.row}>
            <Typography variant='h5'>
              Invoice id:
            </Typography>

            <Typography>{data?.id}</Typography>
          </div>

          <div className={s.row}>
            <Typography variant='h5'>
              Status:
            </Typography>
            <StatusLabel status={data?.status ?? ''} />
          </div>

          <div className={s.row}>
            <Typography variant='h5'>
              Type:
            </Typography>
            <Typography>{data?.operationType}</Typography>
          </div>


            {!!data?.metadata &&
              Object.entries(data.metadata).map(([key, value]) => (
                <div className={s.row} key={key}>
                  <div>
                    <Typography variant='h5' className={s.headText}>
                      {key}:
                    </Typography>
                  </div>
                  <div>
                    <Typography>{value}</Typography>
                  </div>
                </div>
              ))}

        </div>
      )}
    </>
  )
}

export default InvoiceInfo
