import Button from 'components/Button'

import { UserService } from 'services/user.service'
import { useSseStore } from 'store/useSseStore'
import { UserProfile } from 'types/user-profile'
import { getLocale } from 'utils/intl'
import { humanizeBoolean } from 'utils/text'

import { useEffect } from 'react'
import s from './StripeProfileForm.module.scss'

interface Props {
  userData: UserProfile
  isCreated: boolean
  onStripeClick: () => void
}

const StripeProfileForm = ({ userData, isCreated, onStripeClick }: Props) => {
  const stripeSettings = userData.businessSettings.paymentSettings.stripe
  const { countries } = useSseStore()

  const handleClick = () => {
    onStripeClick()
  }

  useEffect(() => {
    if (!userData.id || isCreated || countries?.length) return
    UserService.getCountriesSse({ locale: getLocale() })
  }, [isCreated, userData.id])

  return (
    <div className={s.container}>
      <div className={s.profileInfoWrapper}>
        <div className={s.profileItem}>
          <div className={s.label}>First Name:</div>
          <div className={s.value}>{userData.firstName}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Last Name:</div>
          <div className={s.value}>{userData.lastName}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>User Name:</div>
          <div className={s.value}>{userData.username}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Email:</div>
          <div className={s.value}>{userData.email}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Stripe available:</div>
          <div className={s.value}>
            {humanizeBoolean(stripeSettings.available)}
          </div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Stripe account id:</div>
          <div className={s.value}>{stripeSettings.connectedAccountId}</div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Can payout:</div>
          <div className={s.value}>
            {humanizeBoolean(stripeSettings.canPayout)}
          </div>
        </div>
        <div className={s.profileItem}>
          <div className={s.label}>Can payment:</div>
          <div className={s.value}>
            {humanizeBoolean(stripeSettings.canPayment)}
          </div>
        </div>
      </div>
      <div className={s.buttonsContainer}>
        <Button onClick={handleClick} type='button' fullWidth>
          {isCreated ? 'Edit Stripe Account' : 'Create Stripe Account'}
        </Button>
      </div>
    </div>
  )
}

export default StripeProfileForm
