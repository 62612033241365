import {useMangoPaymentInfo} from '../../hooks/data/useMangoApi';
import {useToken} from '../../hooks/useToken';
import PaperHeader from '../../components/PaperHeader';
import BackSectionLayout from '../../layouts/BackSectionLayout';
import Preloader from '../../components/Preloader';
import {usePath} from '../../hooks/usePath';
import {AppRoute} from '../../assets/const';
import Paper from '../../components/Paper';
import {useInvoice} from '../../hooks/data/useInvoice';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

const enum PaymentFinishStatuses {
    CREATED = 'CREATED',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED',
}

const MangoPaymentInfoPage = () => {
    const {token} = useToken()
    const {data, isLoading} = useMangoPaymentInfo(token)
    const {data: invoice} = useInvoice()
    const rootPath = usePath(AppRoute.Root)
    const navigate = useNavigate()

    // console.log('CHECK STATUS', token, data)

    useEffect(() => {
        if (data?.status === PaymentFinishStatuses.SUCCEEDED && invoice?.redirectUrl) {
            window.location.replace(invoice.redirectUrl)
        }

        if (data?.status === PaymentFinishStatuses.FAILED) {
            navigate(rootPath)
        }
    }, [data, invoice]);

    return <BackSectionLayout text='Back to payment information' path={rootPath}>
        <Paper textAlign='center' style={{maxWidth: '564px', alignSelf: 'center'}}>
            <>
                <PaperHeader text="Payment info status" />
                { (isLoading || data?.status === PaymentFinishStatuses.CREATED) &&
                    <div style={{ position: 'relative', minHeight: '550px' }}>
                        <Preloader />
                    </div>
                }

                { data?.status === PaymentFinishStatuses.FAILED &&
                    <div>ERROR</div>
                }
            </>
        </Paper>
    </BackSectionLayout>
}

export default MangoPaymentInfoPage;